import {
    Box,
    Button,
    Card,
    Divider,
    Paper,
    Skeleton,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { USER_ROLES } from "../../utils/constants";
import AddIcon from "@mui/icons-material/Add";
import { openModal } from "../../store/actions/modalAction";
import DemoUnitProductWiseDataController from "./DemoUnitProductWiseDataController";

const InfoCard = ({ title, total, trialCount, sellCount, onSubmit, inStoreCount, onTrialCount, }) => {
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    return (
        <Card
            onClick={onSubmit}
            sx={{
                width: isSmallScreen ? "49%" : "100%",
                borderRadius: "8px",
                backgroundColor: "#F0F4FA", // Slightly lighter green
                border: "1px solid #D1DFF5", // Softer border color
                mb: 4,
                cursor: "pointer",
            }}
        >
            <Box mt={3} sx={{ backgroundColor: "#F0F4FA" }}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center", // Center the title
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h4" color="#0E2548" sx={{ fontWeight: "bold" }}>
                        {title}
                    </Typography>
                </Box>

                <Box
                    p={4}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#F0F4FA",
                        borderRadius: "4px",
                        // padding: "4px 16px 4px 16px",
                        marginTop: 2, // Add spacing between the title and details box
                    }}
                >

                    <Box display={'flex'} width={"100%"} justifyContent={"space-between"} alignItems={"center"} flexDirection={"row"} sx={{ backgroundColor: "#FFFFFF", borderRadius: "10px" }}>
                        <Box
                            p={2}
                            sx={{
                                textAlign: "center",
                                flex: 1,
                                backgroundColor: "#FFFFFF",
                                borderRadius: "10px",
                            }}
                        >
                            <Typography
                                variant="h5"
                                color="#7588A3;
  "
                                sx={{ fontWeight: "bold", mt: 1 }}
                            >
                                On-Trial
                            </Typography>
                            <Typography
                                variant="h3"
                                color="#0E2548"
                                sx={{ fontWeight: "bold", mt: 1 }}
                            >
                                {onTrialCount}
                            </Typography>
                        </Box>

                        <Box>
                            <Divider
                                orientation="vertical"
                                flexItem
                                textAlign="center"
                                sx={{ borderColor: "#D5E2F6", height: "7vh" }} // Subtle divider
                            />
                        </Box>

                        <Box
                            p={2}
                            sx={{
                                textAlign: "center",
                                flex: 1,
                                backgroundColor: "#FFFFFF",
                                borderRadius: "10px",
                            }}
                        >
                            <Typography
                                variant="h5"
                                fontWeight={"bold"}
                                color="#7588A3"
                                sx={{ fontWeight: "bold", mt: 1 }}
                            >
                                In-Store
                            </Typography>
                            <Typography
                                variant="h3"
                                color="#0E2548"
                                sx={{ fontWeight: "bold", mt: 1 }}
                            >
                                {inStoreCount}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Card>
    );
};

const Franchise = ({ list, countLoading, onChannelSaleClick, id }) => {
    const { user } = useSelector((state) => state);
    const dispatch = useDispatch();

    const onView = (idd) => {
        dispatch(
            openModal(
                <DemoUnitProductWiseDataController id={id} idd={idd} />,
                "md",
                false,
                "productstock"
            )
        );
    };
    return (
        <Box sx={{ p: 2, height: "100%", width: "100%", backgroundColor: "white" }}>

            <Box p={2} display={"flex"} justifyContent={"space-between"} width={"100%"}>
                <Box p={2} width={"100%"} display={"flex"} justifyContent={"flex-start"}>
                    {(user.data.role == USER_ROLES.FRANCHISE_PARTNER || user.data.role == USER_ROLES.FRANCHISE_STAFF)  && <Typography variant="h2" color="black" >
                        Demo Units
                    </Typography>}
                </Box>

                {user.data.role == USER_ROLES.FRANCHISE_PARTNER && <Box p={2}>
                    <Button
                        onClick={onChannelSaleClick}
                        sx={{
                            width: "12vw",
                            height: "5vh",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                        variant="outlined"
                    >
                        <AddIcon />
                        <Typography variant="h4" sx={{ display: "flex" }}>
                            Sale
                        </Typography>
                    </Button>
                </Box>}
            </Box>

            <Box>
                {countLoading ? (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                            height: "15vh",
                        }}
                    >
                        {[0, 1, 2, 3].map((row) => (
                            <Skeleton
                                key={row}
                                variant="rectangular"
                                width={"24%"}
                                height={120}
                                sx={{
                                    borderRadius: "8px",
                                    boxShadow:
                                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                                }}
                            />
                        ))}
                    </Box>
                ) : (
                    <Box
                        mt={4}
                        gap={4}
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                            height: "100%",
                            width: "100%",
                            alignItems: "center",
                        }}
                    >
                        {list?.allStatusCounts?.map((temp) => (
                            <InfoCard
                                onSubmit={() => {
                                    onView(temp?._id);
                                }}
                                title={temp?.product_name}
                                inStoreCount={temp?.in_store_count ?? 0}
                                onTrialCount={temp?.on_trials_count ?? 0}
                            />

                        ))}
                    </Box>

                )}
            </Box>
        </Box>
    );
};

export default Franchise;
