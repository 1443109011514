import { Box, IconButton, Paper, Typography } from "@mui/material";
import CustomDatePicker from "./CustomDatePicker";
import { KeyboardDoubleArrowLeft, KeyboardArrowLeft, KeyboardArrowRight, KeyboardDoubleArrowRight } from '@mui/icons-material';
import moment from "moment";


const CustomisedDayPicker = ({ date, setDate }) => {
  return (
    <Paper
      sx={{
        display: 'flex',
        padding: 2,
        flex: 1,
        backgroundColor: "#E6E6E6",
        height: '100%',
        boxShadow: 'none',
        border: '1px solid #E6E6E6',
        marginBottom: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <IconButton
          onClick={() => setDate(moment(date).subtract(1, 'month'))}
          disabled={date.get('month') === 0}
        >
          <KeyboardDoubleArrowLeft
            color={date.get('month') === 0 ? '#0D0D0D' : 'primary'}
          />
        </IconButton>
        <IconButton
          onClick={() => setDate(moment(date).subtract(1, 'day'))}
        >
          <KeyboardArrowLeft color="primary" />
        </IconButton>
      </Box>

      <Typography variant="h3" sx={{ display: "flex", flex: 1, justifyContent: 'center', alignItems: 'center' }} >
        {date && date.format('DD MMMM, YYYY')}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <IconButton
          onClick={() => setDate(moment(date).add(1, 'day'))}
        >
          <KeyboardArrowRight color="primary" />
        </IconButton>
        <IconButton
          onClick={() => setDate(moment(date).add(1, 'month'))}
          disabled={date.get('month') === 11}
        >
          <KeyboardDoubleArrowRight
            color={date.get('month') === 11 ? 'grey' : 'primary'}
          />
        </IconButton>

        <CustomDatePicker date={date} setDate={setDate} />
      </Box>
    </Paper>
  );
};
export default CustomisedDayPicker;