import { Height, Menu } from "@mui/icons-material";
import { Box, IconButton, Paper, Typography, useMediaQuery } from "@mui/material"



import { useSelector } from 'react-redux'

import Profile from "./Profile";
// import NotificationModel from "../../../pages/notification/NotificationModel";
import { memo } from "react";
// import { USER_ROLES } from "../../../utils/constants/main";
import Logo from "./Logo"
import { findNameByRole } from "../../../utils/main";



const headerStyle = (theme) => ({
    width: "100%",
    background: "white",
    position: "sticky",
    top: "0px",
    display: "flex",
    alignItems: "center",
    pt: 2,
    pb: 4,
    pr: 3,
    height:"72px",
    // borderBottom: "4px solid " + theme.palette.grey.main,
    zIndex: 11,
    // borderRadius: "0px",
  });
  
  const Header = ({ open, setOpen }) => {
    const user = useSelector((state) => state.user);
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
    
    // Get user role using the helper function
    const role = findNameByRole(user?.data?.role);

  
    return (
      <Paper elevation={0} sx={headerStyle}>
        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          {!open && (
            <Box sx={{ width: "17vw", height: "5vh" }}>
              <Logo />
            </Box>
          )}
  
          <Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
            {isSmallScreen ? (
              <IconButton onClick={() => setOpen(!open)}>
                <Menu />
              </IconButton>
            ) : (
              <></>
            )}
  
            {/* Centered User Info */}
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor:'white',
                justifyContent: "center",
                zIndex:"1",
                flex: 1,
              }}
            >
              <Typography variant="h2" >{user?.data?.name || "Guest User"}</Typography>
              <Typography variant="h5" color="textSecondary">
                {role || "Unknown Role"}
              </Typography>
            </Box> */}
          </Box>
  
          <Box mr={2}>
            <Profile />
          </Box>
        </Box>
      </Paper>
    );
  };
  
  export default memo(Header);