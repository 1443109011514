import axios from "axios";
import endpoints from "./endpoints";
import { getHeaders } from "../utils/main";

export const getPaymentURL = async (data) => {
  const callResponse = await axios({
    url: endpoints.getPaymentUrl,
    method: "post",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const addPaymentWithUPI = async (data) => {
  const callResponse = await axios({
    url: endpoints.addPaymentWithUpi,
    method: "post",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};

export const updatePaymentApi = async (data) => {
  const callResponse = await axios({
    url: endpoints.updatePaymentApi,
    method: "patch",
    headers: getHeaders(),
    data,
  })
    .then((response) => response.data)
    .catch((err) => err.response.data);

  return callResponse;
};
