import React, { useEffect, useMemo, useState } from 'react'
import IncentiveListUi from './IncentiveListUi'
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { CreateIncentiveApi, FetchIncentiveApi } from '../../apis/incentive.api';
import { callApiAction } from '../../store/actions/commonAction';
import { SNACK_BAR_VARIETNS, USER_ROLES } from '../../utils/constants';
import { Box, Chip, Tooltip } from '@mui/material';
import { closeModal, openModal } from '../../store/actions/modalAction';
import MessageDilog from '../../components/texts/MessageDilog';
import { callSnackBar } from '../../store/actions/snackbarAction';

const ActionComponent = ({ params, setcallBack, callBack }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const UpdateFun = async (e) => {
        e.preventDefault()
        setLoading(true);
        dispatch(
            callApiAction(
                async () => await CreateIncentiveApi({
                    sellerId: params?.sellerId,
                    customerId: params?.customerId,
                    salesAmount: params?.amount,
                    incentivePercentage: params?.incentivePercentage,
                    incentiveAmount: params?.incentiveAmount,
                    isPaid: true
                }),
                (response) => {
                    setLoading(false);
                    setcallBack({ ...callBack, data: false })
                    dispatch(closeModal("messagedialogupdate"));
                    dispatch(callSnackBar(params?.sellerName + "Status changes Successfully", SNACK_BAR_VARIETNS.suceess))
                },
                (err) => {
                    setLoading(false);
                }
            )
        );

    }

    const statusUpdate = () => {
        dispatch(
            openModal(
                <MessageDilog
                    onSubmit={(e) => UpdateFun(e)}
                    title="Alert!"
                    modalId="messagedialogupdate"
                    message={`Are you sure Incentive Amount has been Paid ?`}
                    loading={loading}
                />,
                "sm",
                false,
                "messagedialogupdate"
            )
        );

    }

    return (
        <>
            <Box sx={{ width: "100%", alignItems: "center", display: "flex" }}>
                {params?.isPaid === true ? (
                    <Tooltip title="Paid">
                        <Chip label="Paid" color='success' />
                    </Tooltip>
                ) : (
                    <Tooltip title="UnPaid" onClick={statusUpdate}>
                        <Chip label="UnPaid" color='error' />
                    </Tooltip>
                )}
            </Box>
        </>
    );

};

const IncentiveListController = () => {
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState({});
    const dispatch = useDispatch();
    const [filters, setFilters] = useState({
        err: "",
        pageNo: 1,
        pageSize: 10,
        date: moment(),
        data: false,
        searchable: ["first_name", "last_name"],
        searchableFields: ["name"],
        search: "",
        sort: "createdAt",
        sortDirection: -1,
    });
    const [callBack, setcallBack] = useState({ data: false });
    const { user } = useSelector(state => state)
    const TabAccess = (user) => {
        switch (user) {
            case USER_ROLES.WEHEAR_ADMIN:
                return 2;
            case USER_ROLES.SALES_CHANNEL_PARTNER:
                return 2;
            case USER_ROLES.FRANCHISE_PARTNER:
                return 3;
            case USER_ROLES.SALES_STAFF:
                return 5;
            default:
                return 2;
        }
    }
    const [step, setStep] = useState(TabAccess(user.data.role))

    const handleDateChange = (newDate) => {
        setFilters({ ...filters, date: newDate });
    };

    const columns = useMemo(
        () => [

            {
                id: 1,
                fieldName: "date",
                label: "Date",
                align: "left",
                sort: true,
                renderValue: (params, setParams) => moment(params.date).format("DD/MM/YYYY")
            },

            {
                id: 6,
                fieldName: "sellerName",
                label: "Seller Name",
                align: "left",
                sort: true,
            },
            {
                id: 2,
                fieldName: "customerName",
                label: "Name",
                align: "left",
                sort: true,
            },
            {
                id: 8,
                fieldName: "amount",
                label: "Product Amount",
                align: "left",
                sort: false,
            },
            {
                id: 9,
                fieldName: "incentivePercentage",
                label: "Seller Percentage",
                align: "left",
                sort: false,
            },
            {
                id: 10,
                fieldName: "incentiveAmount",
                label: "Seller Incentive Amount",
                align: "left",
                sort: false,
            },
            {
                id: 7,
                fieldName: "",
                label: "Status",
                align: "right",
                renderValue: (params, setParams) => (
                    <ActionComponent
                        params={params}
                        setcallBack={setcallBack}
                        callBack={callBack}
                    />
                ),
            },
        ],
        [dispatch]
    );

    const FetchIncentiveList = () => {
        setLoading(true);
        dispatch(
            callApiAction(
                async () => await FetchIncentiveApi({ ...filters, role: step, userId: user.data._id, user: user.data.role }),
                (response) => {
                    setList(response);
                    setLoading(false);
                },
                (err) => {
                    setLoading(false);
                }
            )
        );
    };

    useEffect(() => {
        if (callBack.data) {
            FetchIncentiveList();
        } else {
            setcallBack({ ...callBack, data: true })
        }
    }, [step, filters, callBack]);


    return (<IncentiveListUi
        date={filters.date}
        setDate={handleDateChange}
        loading={loading}
        columns={columns}
        list={list}
        filters={filters}
        setFilters={setFilters}
        setStep={setStep}
        step={step}
    />)
}

export default IncentiveListController