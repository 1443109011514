import React from "react";
import { Box, Typography, ListItem, Step, StepLabel, Stepper } from "@mui/material";
import CustomDialog from "../../../../components/layouts/common/CustomDialog";
import PurchasedProductDetailsUi from "./PurchasedProductDetailsUi";
import PurchasedProductPaymentUi from "./PurchasedProductPayment";


const PurchasedProductUi = ({loading, fields, setFields,onSubmit,step,setStep,onPrevStep }) => {
  const steps = [
    'Purchased Product Details',
    'Payment Options',
  ];
  
  return (
    <CustomDialog
    loading={loading}
    id={"purchased-product"}
    title={`Purchased Product`}
    closeText={step == 0 ? "Close" : "Back"}
    onClose={onPrevStep}
    onSubmit={onSubmit}
    disableDirectClose={true}
    confirmText={step== 0 ? "Next" : "Payment Received"}
  >
     <Box sx={{ width: '100%' }} mb={2}>
      <Stepper activeStep={step} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
    {step == 0 && <PurchasedProductDetailsUi fields={fields} setFields={setFields} />}

    {step == 1 && <PurchasedProductPaymentUi fields={fields} setFields={setFields} />}

    </CustomDialog>
  );
};

export default PurchasedProductUi;
