import * as React from "react";
import { useState } from "react";
import moment from "moment";
import useValidate from "../../../store/hooks/useValidator";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../../utils/constants";
import { callApiAction } from "../../../store/actions/commonAction";
import { useDispatch, useSelector } from "react-redux";
import { addCustomerApi, updateCustomerApi } from "../../../apis/customer.api";
import { useNavigate } from "react-router";
import { closeModal } from "../../../store/actions/modalAction";
import CustomerCreateUi from "./CustomerCreateUi";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import AudioGram from "./AudioGram";
import Payment from "./PaymentDetails/Payment";
import SubmitButton from "../../../components/button/SubmitButton";
import { ChevronLeft } from "@mui/icons-material";
import TrialProductUi from "./TrialController/TrialProductUi";

export default function CustomerCreateController() {
  const validate = useValidate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);

  const [step, setStep] = useState(0);

  const stepsLabel = [
    "Basic Information",
    "Audiogram"
  ];

  const [fields, setFields] = useState({
    err: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    phone: "",
    email: "",
    age: 0,
    dob: moment(),
    gender: null,
    address: "",
    state: "",
    city: "",
    zip_code: null,
    suggested_product: [],
    sold_details: {
      box_id: "",
      date: moment(new Date()).toISOString(),
      amount: "",
      product: "",
    },
    level_of_hearing_loss: null,
    type_of_hearing_loss: null,

    allowed_ac: true,
    lf1: 0,
    lf2: 0,
    lf3: 0,
    lf4: 0,
    lf5: 0,
    lf6: 0,
    lf7: 0,
    lf8: 0,

    rf1: 0,
    rf2: 0,
    rf3: 0,
    rf4: 0,
    rf5: 0,
    rf6: 0,
    rf7: 0,
    rf8: 0,

    allowed_bc: true,

    blf1: 0,
    blf2: 0,
    blf3: 0,
    blf4: 0,
    blf5: 0,
    blf6: 0,
    blf7: 0,
    blf8: 0,

    brf1: 0,
    brf2: 0,
    brf3: 0,
    brf4: 0,
    brf5: 0,
    brf6: 0,
    brf7: 0,
    brf8: 0,
  });

  const validationSchema = React.useMemo(
    () => [
      {
        required: true,
        value: fields.first_name,
        field: "first_name",
        message: "First Name",
      },
      {
        required: true,
        value: fields.phone,
        field: "phone_no",
        message: "Phone number",
      },
      {
        required: true,
        value: fields.email,
        field: "email",
        isEmail: true,
        message: "Valid email",
      },
      {
        required: true,
        value: fields.dob,
        field: "dob",
        message: "Date of Birth",
      },
      {
        required: true,
        value: fields.address,
        field: "address",
        message: "Address",
      },
      {
        required: true,
        value: fields.state,
        field: "state",
        message: "State",
      },
      {
        required: true,
        value: fields.city,
        field: "city",
        message: "City",
      },
      {
        required: true,
        value: fields.zip_code,
        field: "zip_code",
        message: "Zip Code",
      },
      {
        required: true,
        value: fields.gender,
        field: "gender",
        message: "Gender",
      },
    ],
    [fields]
  );

  const validationSchemaAudiograms = React.useMemo(
    () => [
      {
        value: fields.lf1,
        field: "lf1",
      },
      {
        value: fields.lf2,
        field: "lf2",
      },
      {
        value: fields.lf3,
        field: "lf3",
      },
      {
        value: fields.lf4,
        field: "lf4",
      },
      {
        value: fields.lf5,
        field: "lf5",
      },
      {
        value: fields.lf6,
        field: "lf6",
      },
      {
        value: fields.lf7,
        field: "lf7",
      },
      {
        value: fields.rf1,
        field: "rf1",
      },
      {
        value: fields.rf2,
        field: "rf2",
      },
      {
        value: fields.rf3,
        field: "rf3",
      },
      {
        value: fields.rf4,
        field: "rf4",
      },
      {
        value: fields.rf5,
        field: "rf5",
      },
      {
        value: fields.rf6,
        field: "rf6",
      },
      {
        value: fields.rf7,
        field: "rf7",
      },
      {
        value: fields.blf1,
        field: "blf1",
      },
      {
        value: fields.blf2,
        field: "blf2",
      },
      {
        value: fields.blf3,
        field: "blf3",
      },
      {
        value: fields.blf4,
        field: "blf4",
      },
      {
        value: fields.blf5,
        field: "blf5",
      },
      {
        value: fields.blf6,
        field: "blf6",
      },
      {
        value: fields.blf7,
        field: "blf7",
      },
      {
        value: fields.brf1,
        field: "brf1",
      },
      {
        value: fields.brf2,
        field: "brf2",
      },
      {
        value: fields.brf3,
        field: "brf3",
      },
      {
        value: fields.brf4,
        field: "brf4",
      },
      {
        value: fields.brf5,
        field: "brf5",
      },
      {
        value: fields.brf6,
        field: "brf6",
      },
      {
        value: fields.brf7,
        field: "brf7",
      },
      {
        required: true,
        value: fields.level_of_hearing_loss,
        field: "level_of_hearing_loss",
        message: "Level of Hearing Loss",
      },
      {
        required: true,
        value: fields.type_of_hearing_loss,
        field: "type_of_hearing_loss",
        message: "Type of Hearing Loss",
      },
      {
        required: true,
        value: fields.suggested_product.length > 0 && fields.suggested_product,
        field: "suggested_product",
        message: "Suggested Product",
      },
    ],
    [fields]
  );

  const validationSchemaTrial = React.useMemo(
    () => [
      {
        required: true,
        value: fields?.trial?.box_id,
        field: "box_id",
        message: "Box ID",
      },
      {
        required: true,
        value: fields?.trial?.date,
        field: "date",
        message: "Date",
      },
      {
        required: true,
        value: fields?.trial?.amount,
        field: "amount",
        message: "Amount",
      },
      {
        required: true,
        value: fields?.trial?.product,
        field: "product",
        message: "Product",
      },
    ],
    [fields]
  );

  const validationSchemaPurchase = React.useMemo(
    () => [
      {
        required: true,
        value: fields.sold_details?.box_id,
        field: "box_id",
        message: "Box ID",
      },
      {
        required: true,
        value: fields?.sold_details?.date,
        field: "date",
        message: "Date",
      },
      {
        required: true,
        value: fields?.sold_details?.amount,
        field: "amount",
        message: "Amount",
      },
      {
        required: true,
        value: fields.sold_details.product,
        field: "product",
        message: "Product",
      },
    ],
    [fields]
  );

  const createFunction = async (activeStep) => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await addCustomerApi({ ...fields }),
        async (response) => {
          setLoading(false);
          dispatch(
            callSnackBar(" Created Successfully", SNACK_BAR_VARIETNS.suceess)
          );
          navigate("/customer/");
        },
        (err) => {
          setLoading(false);
          dispatch(callSnackBar("Error", SNACK_BAR_VARIETNS.error));
          setFields({ ...fields, err });
        }
      )
    );
  };

  const updateFunction = async (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await updateCustomerApi(),
        async (response) => {
          setLoading(false);
          dispatch(
            callSnackBar(" update Successfully", SNACK_BAR_VARIETNS.suceess)
          );
          dispatch(closeModal("CustomerInformation"));
        },
        (err) => {
          setLoading(false);
          dispatch(callSnackBar("Error", SNACK_BAR_VARIETNS.error));
          setFields({ ...fields, err });
        }
      )
    );
  };

  const handleNext = () => {
    const validationSchemaArr = [
      validationSchema,
      validationSchemaAudiograms,
      validationSchemaTrial,
    ];
    if (step == 1) {
      createFunction();
      return;
    }
    const validationResponse = validate(validationSchemaArr[step]);

    if (validationResponse === true) {
      setStep(step + 1);
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          width: "100%",
          height: "100%",
          padding: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box width={"100%"} height={"100%"}>
          <Box width={"100%"} sx={{ display: "flex" }}>
            <IconButton
              onClick={() => {
                navigate("/customer/");
              }}
            >
              <ChevronLeft sx={{ cursor: "pointer" }} />
            </IconButton>
            <Box width={"100%"}>
              {" "}
              <Stepper activeStep={step} alternativeLabel>
                {stepsLabel.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Box>
          <Box width={"100%"} height={"88%"} mt={2}>
            {step == 0 && (
              <CustomerCreateUi fields={fields} setFields={setFields} />
            )}
            {step == 1 && <AudioGram fields={fields} setFields={setFields} />}
            {step == 2 && (
              <TrialProductUi fields={fields} setFields={setFields} />
            )}
            {step == 3 && <Payment fields={fields} setFields={setFields} />}
          </Box>
        </Box>
        {/* {step != 3 && ( */}
        <Box
          pt={3}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            borderTop: "1px #CCCCCC solid",
          }}
        >
          {" "}
          <SubmitButton
            loading={loading}
            onClick={handleNext}
            sx={{ width: "150px" }}
            variant="contained"
            title={step == 1 ? "Submit" : "Next"}
          />
        </Box>
        {/* )} */}
      </Paper>
    </>
  );
}
