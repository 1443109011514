import { Box, Grid, Typography } from '@mui/material';
import React, { memo } from 'react';
import CustomDialog from '../../components/layouts/common/CustomDialog';

const DetailsBox = memo(({ title, data = [{ title: "", value: "" }] }) => {
    return (
        <Box
            sx={{
                width: "100%",
                border: "1px solid #D3D3D3",
                borderRadius: "8px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                overflow: "hidden",
                backgroundColor: "#FFFFFF"
            }}
        >
            <Box
                p={2}
                sx={{
                    backgroundColor: "#F5F5F5",
                    borderBottom: "1px solid #D3D3D3",
                }}
            >
                <Typography
                    variant="h5"
                    sx={{ color: "#333333", fontWeight: "bold", textAlign: "center" }}
                >
                    {title}
                </Typography>
            </Box>
            <Grid container p={2} spacing={2}>
                {data.map((item, index) => (
                    <React.Fragment key={index}>
                        <Grid item xs={6} sm={6} md={3}>
                            <Typography
                                variant="subtitle1"
                                sx={{ fontWeight: "bold", color: "#555555" }}
                            >
                                {item?.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: "#777777",
                                    textAlign: "left",
                                    wordWrap: "break-word",
                                }}
                            >
                                {item?.value}
                            </Typography>
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>
        </Box>
    );
});

const CustomerInformation = ({ params }) => {
    return (
        <CustomDialog id={"CustomerInformation"} title={`Customer Information`}>
            <Box sx={{ padding: 2 }}>
                <DetailsBox
                    title="Customer Details"
                    data={[
                        {
                            title: "Customer Name",
                            value: params?.customer_name ?? "NA",
                        },
                        {
                            title: "Customer Phone",
                            value: params?.customer_phone ?? "NA",
                        },
                        {
                            title: "Customer Email",
                            value: params?.customer_email ?? "NA",
                        },
                        {
                            title: "Customer Address",
                            value: params?.customer_address ?? "NA",
                        },
                    ]}
                />
                <Box mt={2}>
                    <DetailsBox
                        title="Store Details"
                        data={[
                            {
                                title: "Name",
                                value: params?.store_id?.name ?? "NA",
                            },
                            {
                                title: "Email",
                                value: params?.store_id?.email ?? "NA",
                            },
                            {
                                title: "Phone",
                                value: params?.store_id?.phone ?? "NA",
                            },
                            {
                                title: "State",
                                value: params?.store_id?.state ?? "NA",
                            },
                            {
                                title: "Zip Code",
                                value: params?.store_id?.zip_code ?? "NA",
                            },
                            {
                                title: "Address",
                                value: params?.store_id?.address ?? "NA",
                            },
                        ]}
                    />
                </Box>
            </Box>
        </CustomDialog>
    );
};

export default CustomerInformation;
