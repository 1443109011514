const MODULES = {
  DASHBOARD_MODULE: 1,
  PRODUCT_MODULE: 2,
  USER_MODULE: 3,
  // STOCK_MODULE: 4,
  PRODUCT_COLOR_MODULE: 5,
  PRODUCT_TYPE_MODULE: 6,
  CUSTOMER_LIST_MODULE: 7,
  // LEDGER_MODULE: 8,
  RETURN: 9,
  INVENTORY_MODULE: 10,
  CUSTOMER_CREATE_MODULE: 11,
  REPLACEMENT_MODULE: 13,
  DEMO_UNITS: 14,
  SALES: 15,
  TRIALS: 16,
  RETURN: 17,
  INCENTIVE: 18,
};

export default MODULES;
