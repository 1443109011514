import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Box,
} from "@mui/material";
// import { USER_ROLES } from "../../../utils/constants";
// import { findObjectKeyByValue } from "../../../utils/main";

const ProductListUi = ({ dataList }) => {
  const headers = ["Unit Sold", "Unit Trials", "Return", "Demo Units In-Store"];

  return (
    <Box p={4}>
      {/* <Paper elevation={1} sx={{ padding: 5, marginTop: 2 }}> */}
        <Typography variant="h4" align="left" color="#1B4381" gutterBottom>
          {/* {role == USER_ROLES.SALES_CHANNEL_PARTNER ? findObjectKeyByValue(USER_ROLES.SALES_CHANNEL_PARTNER,USER_ROLES) : findObjectKeyByValue(USER_ROLES.FRANCHISE_PARTNER,USER_ROLES)} wise Analysis */}
          Product List Analysis
        </Typography>
        <TableContainer
          sx={{
            borderRadius: "4px",
            border: "1px solid rgb(129, 166, 222)",
          }}
        >
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#1B4381" }}>
                <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                  Product Name
                </TableCell>
                {headers.map((header, index) => (
                  <TableCell
                    key={index}
                    align="center"
                    sx={{ color: "#fff", fontWeight: "bold" }}
                  >
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataList?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {row?.product_name ?? "NA"}
                  </TableCell>
                  <TableCell align="center">
                    {row?.statusCounts?.purchased ?? 0}
                  </TableCell>
                  <TableCell align="center">
                    {row?.statusCounts?.on_trials ?? 0}
                  </TableCell>
                  <TableCell align="center">
                    {row?.statusCounts?.returned ?? 0}
                  </TableCell>
                  <TableCell align="center">
                    {row?.statusCounts?.in_store ?? 0}
                  </TableCell>
                  {/* <TableCell align="center">{row?.customer?.trial_units ?? 0}</TableCell>
                      <TableCell align="center">{row?.demounits?.demo_units ?? 0}</TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      {/* </Paper> */}
    </Box>
  );
};
export default ProductListUi;
