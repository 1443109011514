import { Box, Typography, TextField, useMediaQuery, styled } from "@mui/material";
import Slide from "@mui/material/Slide";
import mapImage from "../../assets/images/map.svg";
import { useRef } from "react";
import { useLocation } from "react-router";
import Dms_logo from "../../assets/images/DMS_LOGO.svg"

const LoginBox = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: "25%",
    borderBottom: '8px solid #1A4381',
    borderTop: '8px solid #1A4381',
    transform: "translateY(-50%)",
    width: "511px",
    background: "#fff",
    padding: "2rem",
    borderRadius: "16px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
        width: "90%",
    },
}));


const MainUi = ({ onSubmit, ...props }) => {
    const ref = useRef();
    const location = useLocation();
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"))


    return (
        <Box
            sx={{
                height: "100vh",
                width: "100vw",
                backgroundColor: "#f4f4f4",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
            }}
        >
            {/* Map Section */}
            <Box
                sx={{
                    width: "100%",
                    height: "50%",
                    background: `url(${mapImage}) no-repeat center/cover`,
                }}
            />

            {/* Login Form Section */}
            <Box
                sx={{
                    width: "100%",
                    height: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                ref={ref}
            >
                <Slide
                    timeout={400}
                    direction={
                        location.pathname !== "/sign-up"
                            ? "right"
                            : "left"
                    }
                    in={true}
                    container={ref.current}
                >
                    <LoginBox component="form" onSubmit={onSubmit}>
                        <Box sx={{ display: "flex", alignItems: 'center', flexDirection: 'column' }}>
                            <img src={Dms_logo} />
                        </Box>

                        <Box sx={{ width: "100%" }}>{props.children}</Box>
                    </LoginBox>
                </Slide>

            </Box>
        </Box>
    );
};

export default MainUi;
