import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Grid2,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  LEVEL_OF_HEARING_LOSS,
  TYPE_OF_HEARING_LOSS,
} from "../../../utils/constants";
import ACIMG from "../../../assets/images/Frame 1922.png";
import BCIMG from "../../../assets/images/Frame 1929.png";
import ARROW_RIGHT from "../../../assets/images/arrow-right (4).png";
import ARROW_LEFT from "../../../assets/images/arrow-right (5).png";
import RESET_LEFT from "../../../assets/images/arrow-right (6).png";
import RESET_RIGHT from "../../../assets/images/arrow-right (7).png";
import { fetchProductApi } from "../../../apis/product.api";
import { callApiAction } from "../../../store/actions/commonAction";
import ChartComponent from "../../../components/ChartComponent";

const AudioGram = ({ only_view, fields, setFields, loading }) => {
  const [mode, setMode] = useState("");
  const [rightAcData, setRightAcData] = useState([]);
  useEffect(() => {
    setRightAcData([
      fields.rf1,
      fields.rf2,
      fields.rf3,
      fields.rf4,
      fields.rf5,
      fields.rf6,
      fields.rf7,
      fields.rf8,
    ]);
  }, [fields.rf1]);
  const [rightBcData, setRightBcData] = useState([]);

  useEffect(() => {
    setRightBcData([
      fields.brf1,
      fields.brf2,
      fields.brf3,
      fields.brf4,
      fields.brf5,
      fields.brf6,
      fields.brf7,
      fields.brf8,
    ]);
  }, [fields.brf1]);

  const [rightUclData, setRightUclData] = useState([
    fields.urf1,
    fields.urf2,
    fields.urf3,
    fields.urf4,
    fields.urf5,
    fields.urf6,
    fields.urf7,
    fields.urf8,
  ]);
  const [rightMclData, setRightMclData] = useState([
    fields.mrf1,
    fields.mrf2,
    fields.mrf3,
    fields.mrf4,
    fields.mrf5,
    fields.mrf6,
    fields.mrf7,
    fields.mrf8,
  ]);
  const [leftAcData, setLeftAcData] = useState([]);
  useEffect(() => {
    setLeftAcData([
      fields.lf1,
      fields.lf2,
      fields.lf3,
      fields.lf4,
      fields.lf5,
      fields.lf6,
      fields.lf7,
      fields.lf8,
    ]);
  }, [fields.lf1]);
  const [leftUclData, setLeftUclData] = useState([
    fields.ulf1,
    fields.ulf2,
    fields.ulf3,
    fields.ulf4,
    fields.ulf5,
    fields.ulf6,
    fields.ulf7,
    fields.ulf8,
  ]);
  const [leftBcData, setLeftBcData] = useState([]);
  useEffect(() => {
    setLeftBcData([
      fields.blf1,
      fields.blf2,
      fields.blf3,
      fields.blf4,
      fields.blf5,
      fields.blf6,
      fields.blf7,
      fields.blf8,
    ]);
  }, [fields.blf1]);
  const [leftMclData, setLeftMclData] = useState([
    fields.mlf1,
    fields.mlf2,
    fields.mlf3,
    fields.mlf4,
    fields.mlf5,
    fields.mlf6,
    fields.mlf7,
    fields.mlf8,
  ]);

  const handleMirrorData_r_to_l = () => {
    setFields({
      ...fields,
      err: "",
      lf1: fields?.rf1,
      lf2: fields?.rf2,
      lf3: fields?.rf3,
      lf4: fields?.rf4,
      lf5: fields?.rf5,
      lf6: fields?.rf6,
      lf7: fields?.rf7,
      lf8: fields?.rf8,

      blf1: fields?.brf1,
      blf2: fields?.brf2,
      blf3: fields?.brf3,
      blf4: fields?.brf4,
      blf5: fields?.brf5,
      blf6: fields?.brf6,
      blf7: fields?.brf7,
      blf8: fields?.brf8,

      ulf1: fields?.urf1,
      ulf2: fields?.urf2,
      ulf3: fields?.urf3,
      ulf4: fields?.urf4,
      ulf5: fields?.urf5,
      ulf6: fields?.urf6,
      ulf7: fields?.urf7,
      ulf8: fields?.urf8,

      mlf1: fields?.mrf1,
      mlf2: fields?.mrf2,
      mlf3: fields?.mrf3,
      mlf4: fields?.mrf4,
      mlf5: fields?.mrf5,
      mlf6: fields?.mrf6,
      mlf7: fields?.mrf7,
      mlf8: fields?.mrf8,
    });
    setLeftAcData(rightAcData);
    setLeftBcData(rightBcData);
    setLeftUclData(rightUclData);
    setLeftMclData(rightMclData);
  };

  const handleMirrorData_l_to_r = () => {
    setFields({
      ...fields,
      err: "",
      rf1: fields?.lf1,
      rf2: fields?.lf2,
      rf3: fields?.lf3,
      rf4: fields?.lf4,
      rf5: fields?.lf5,
      rf6: fields?.lf6,
      rf7: fields?.lf7,
      rf8: fields?.lf8,

      brf1: fields?.blf1,
      brf2: fields?.blf2,
      brf3: fields?.blf3,
      brf4: fields?.blf4,
      brf5: fields?.blf5,
      brf6: fields?.blf6,
      brf7: fields?.blf7,
      brf8: fields?.blf8,

      urf1: fields?.ulf1,
      urf2: fields?.ulf2,
      urf3: fields?.ulf3,
      urf4: fields?.ulf4,
      urf5: fields?.ulf5,
      urf6: fields?.ulf6,
      urf7: fields?.ulf7,
      urf8: fields?.ulf8,

      mrf1: fields?.mlf1,
      mrf2: fields?.mlf2,
      mrf3: fields?.mlf3,
      mrf4: fields?.mlf4,
      mrf5: fields?.mlf5,
      mrf6: fields?.mlf6,
      mrf7: fields?.mlf7,
      mrf8: fields?.mlf8,
    });
    setRightAcData(leftAcData);
    setRightBcData(leftBcData);
    setRightUclData(leftUclData);
    setRightMclData(leftMclData);
  };

  const handleToggleAC = () => {
    setFields((prevFields) => ({
      ...prevFields,
      err: "",
      allowed_ac: !prevFields?.allowed_ac,
    }));
    setMode("ac");
  };

  const handleToggleBC = () => {
    setFields((prevFields) => ({
      ...prevFields,
      err: "",
      allowed_bc: !prevFields?.allowed_bc,
    }));
    setMode("bc");
  };

  const handleToggleMcl = () => {
    setFields((prevFields) => ({
      ...prevFields,
      err: "",
      allowed_mcl: !prevFields?.allowed_mcl,
    }));
    setMode("mcl");
  };

  const handleToggleUCL = () => {
    setFields((prevFields) => ({
      ...prevFields,
      err: "",
      allowed_ucl: !prevFields?.allowed_ucl,
    }));
    setMode("ucl");
  };

  const handleResetRightChart = () => {
    setRightAcData(new Array(7).fill(null));
    setRightBcData(new Array(7).fill(null));
    setRightUclData(new Array(7).fill(null));
    setRightMclData(new Array(7).fill(null));
    setFields({
      ...fields,
      err: "",
      rf1: null,
      rf2: null,
      rf3: null,
      rf4: null,
      rf5: null,
      rf6: null,
      rf7: null,
      rf8: null,

      brf1: null,
      brf2: null,
      brf3: null,
      brf4: null,
      brf5: null,
      brf6: null,
      brf7: null,
      brf8: null,

      urf1: null,
      urf2: null,
      urf3: null,
      urf4: null,
      urf5: null,
      urf6: null,
      urf7: null,
      urf8: null,

      mrf1: null,
      mrf2: null,
      mrf3: null,
      mrf4: null,
      mrf5: null,
      mrf6: null,
      mrf7: null,
      mrf8: null,
    });
  };

  const handleResetLeftChart = () => {
    setLeftAcData(new Array(7).fill(null));
    setLeftBcData(new Array(7).fill(null));
    setLeftUclData(new Array(7).fill(null));
    setLeftMclData(new Array(7).fill(null));

    setFields({
      ...fields,
      err: "",
      lf1: null,
      lf2: null,
      lf3: null,
      lf4: null,
      lf5: null,
      lf6: null,
      lf7: null,
      lf8: null,

      blf1: null,
      blf2: null,
      blf3: null,
      blf4: null,
      blf5: null,
      blf6: null,
      blf7: null,
      blf8: null,

      ulf1: null,
      ulf2: null,
      ulf3: null,
      ulf4: null,
      ulf5: null,
      ulf6: null,
      ulf7: null,
      ulf8: null,

      mlf1: null,
      mlf2: null,
      mlf3: null,
      mlf4: null,
      mlf5: null,
      mlf6: null,
      mlf7: null,
      mlf8: null,
    });
  };

  const handleValueChange = (index, value, mode, ear) => {
    if (ear === "right") {
      if (mode === "ac") {
        const newAcData = [...rightAcData];
        newAcData[index] = value;
        setRightAcData(newAcData);
      } else if (mode === "bc") {
        const newBcData = [...rightBcData];
        newBcData[index] = value;
        setRightBcData(newBcData);
      } else if (mode === "ucl") {
        const newUclData = [...rightUclData];
        newUclData[index] = value;
        setRightUclData(newUclData);
        console.log("this is rudren bakvas ", fields);
      } else if (mode === "mcl") {
        const newMclData = [...rightMclData];
        newMclData[index] = value;
        setRightMclData(newMclData);
        console.log("this is rudren bakvas ", fields);
      }
    } else if (ear === "left") {
      if (mode === "ac") {
        const newAcData = [...leftAcData];
        newAcData[index] = value;
        setLeftAcData(newAcData);
      } else if (mode === "bc") {
        const newBcData = [...leftBcData];
        newBcData[index] = value;
        setLeftBcData(newBcData);
      } else if (mode === "ucl") {
        const newUclData = [...leftUclData];
        newUclData[index] = value;
        setLeftUclData(newUclData);
      } else if (mode === "mcl") {
        const newMclData = [...leftMclData];
        newMclData[index] = value;
        setLeftMclData(newMclData);
      }
    }
    const prefix =
      mode === "bc" ? "b" : mode === "ac" ? "" : mode === "ucl" ? "u" : "m";
    const newData = { ...fields };
    newData[`${prefix}${ear === "right" ? "rf" : "lf"}${index + 1}`] = value;
    setFields(newData);
  };

  const [listProblem, setlistProblem] = useState();
  const dispatch = useDispatch();
  const fetchList = () => {
    // setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchProductApi({ allStatus: true }),
        (response) => {
          setlistProblem(response?.result);
          // setLoading(false);
        },
        (err) => {
          // setLoading(false);
          console.log("error", err);
        }
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, []);

  return (
    <>
      <Box height={"10px"}>
        <Typography variant="h5" color={"red"}>
          {fields?.err}
        </Typography>
      </Box>
      <Container maxWidth="xl" sx={{ p: 4 }}>
        <Box
          md={12}
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <Box
            pb={5}
            sx={{
              backgroundColor: "#FFF6F6",
              width: "30%",
              maxHeight: "50vh",
              border: "1px solid rgb(228, 217, 217)",
              borderRadius: "4px",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Typography color="error" variant="h5">
                • Right
              </Typography>
            </Box>

            <ChartComponent
              allowed_ac={fields?.allowed_ac}
              allowed_bc={fields?.allowed_bc}
              allowed_ucl={fields?.allowed_ucl}
              allowed_mcl={fields?.allowed_mcl}
              onValueChange={(index, value, mode) =>
                handleValueChange(index, value, mode, "right")
              }
              data={rightAcData}
              title="AC"
              boneData={rightBcData}
              boneTitle="BC"
              UclData={rightUclData}
              UclTitle="UCL"
              MclData={rightMclData}
              MclTitle="MCL"
              rightEar
              mode={mode}
            />
          </Box>
          {!only_view && !loading && (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Button
                onClick={handleToggleAC}
                sx={{ backgroundColor: mode === "ac" ? "#EDF0F7" : "" }}
              >
                <img
                  src={ACIMG}
                  alt="AC"
                  style={{ maxHeight: "100%", maxWidth: "100%" }}
                />
              </Button>

              <Button
                onClick={handleToggleBC}
                sx={{ backgroundColor: mode === "bc" ? "#EDF0F7" : "" }}
              >
                <img
                  src={BCIMG}
                  alt="BC"
                  style={{ maxHeight: "100%", maxWidth: "100%" }}
                />
              </Button>

              <Box
                sx={{
                  flex: "0",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button onClick={handleMirrorData_r_to_l}>
                  <img
                    src={ARROW_RIGHT}
                    alt="Mirror R to L"
                    style={{ height: "40px" }}
                  />
                </Button>
                <Button onClick={handleMirrorData_l_to_r}>
                  <img
                    src={ARROW_LEFT}
                    alt="Mirror L to R"
                    style={{ height: "40px" }}
                  />
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "48px",
                }}
              >
                <Button onClick={handleResetRightChart}>
                  <img
                    src={RESET_RIGHT}
                    alt="Reset Right"
                    style={{ height: "40px" }}
                  />
                </Button>
                <Button onClick={handleResetLeftChart}>
                  <img
                    src={RESET_LEFT}
                    alt="Reset Left"
                    style={{ height: "40px" }}
                  />
                </Button>
              </Box>
            </Box>
          )}
          <Box
            pb={5}
            sx={{
              backgroundColor: "#F6FAFF",
              width: "30%",
              maxHeight: "50vh",
              border: "1px solid rgb(180, 192, 202)",
              borderRadius: "4px",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Typography color="primary" variant="h5">
                • Left
              </Typography>
            </Box>
            <ChartComponent
              allowed_ac={fields?.allowed_ac}
              allowed_bc={fields?.allowed_bc}
              allowed_ucl={fields?.allowed_ucl}
              allowed_mcl={fields?.allowed_mcl}
              onValueChange={(index, value, mode) =>
                handleValueChange(index, value, mode, "left")
              }
              data={leftAcData}
              title="AC"
              boneData={leftBcData}
              boneTitle="BC"
              UclTitle="UCL"
              UclData={leftUclData}
              MclTitle="MCL"
              MclData={leftMclData}
              rightEar={false}
              mode={mode}
            />
          </Box>
        </Box>
        <Box mt={4} mb={1}>
          <Typography variant="h4">Hearing Loss :</Typography>
        </Box>
        <Grid2 container size={12} spacing={2}>
          <Grid2 item size={{ xs: 12, md: 6 }}>
            <Box
              sx={{
                padding: 2,
                border: "1px solid #1B4381",
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                borderRadius: "4px",
              }}
            >
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <FormLabel id="demo-radio-buttons-group-label">
                  Level :
                </FormLabel>
                <RadioGroup
                  row
                  sx={{ marginLeft: "10px" }}
                  value={fields.level_of_hearing_loss}
                  onChange={(e) =>
                    setFields({
                      ...fields,
                      err: "",
                      level_of_hearing_loss: parseInt(e.target.value),
                    })
                  }
                >
                  {Object.keys(LEVEL_OF_HEARING_LOSS).map((item) => (
                    <FormControlLabel
                      disabled={loading}
                      key={item}
                      value={LEVEL_OF_HEARING_LOSS[item]}
                      control={<Radio />}
                      label={item}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
          </Grid2>
          <Grid2 item size={{ xs: 12, md: 6 }}>
            <Box
              sx={{
                padding: 2,
                border: "1px solid #1B4381",
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                borderRadius: "4px",
              }}
            >
              <FormControl
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <FormLabel id="demo-radio-buttons-group-label">
                  Type :
                </FormLabel>
                <RadioGroup
                  row
                  sx={{ marginLeft: "10px" }}
                  value={fields.type_of_hearing_loss}
                  onChange={(e) =>
                    setFields({
                      ...fields,
                      err: "",
                      type_of_hearing_loss: parseInt(e.target.value),
                    })
                  }
                >
                  {Object.keys(TYPE_OF_HEARING_LOSS).map((item) => (
                    <FormControlLabel
                      disabled={loading}
                      key={item}
                      value={TYPE_OF_HEARING_LOSS[item]}
                      control={<Radio />}
                      label={item}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
          </Grid2>
        </Grid2>
        <Box mt={4}>
          <Typography variant="h4">Suggested Products :</Typography>
        </Box>
        <Box  width={"100%"} display={"flex"} justifyContent={"center"}>
          <Autocomplete
            value={
              fields.suggested_product_id
                ? listProblem?.filter((problem) =>
                    fields.suggested_product_id.includes(problem.product_name)
                  ) || []
                : Array.isArray(fields?.suggested_product) &&
                  fields.suggested_product.length > 0
                ? listProblem?.filter((name) =>
                    fields?.suggested_product?.some(
                      (product) => product?.product_name === name?.product_name
                    )
                  )
                : []
            }
            sx={{ width: "100%", color: "primary.main" }}
            multiple
            onChange={(event, newValue) => {
              setFields({
                ...fields,
                err: "",
                suggested_product: newValue?.map((val) => val?._id),
                suggested_product_id: newValue?.map((val) => val?.product_name),
              });
            }}
            options={listProblem ?? []}
            getOptionLabel={(option) => option.product_name}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip label={option.product_name} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField
                sx={{ width: "100%" }}
                margin="dense"
                {...params}
                label="Products*"
                variant="outlined"
                placeholder="Suggested Products"
              />
            )}
          />
        </Box>
      </Container>
    </>
  );
};

export default memo(AudioGram);
