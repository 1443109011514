import { actions } from "../../utils/constants"


const initialState = {

    Query_data: {},
    Query_loading: false,
    Query_filters: {},

    Count_data: {},
    Count_loading: true,
    Count_filters: {},

    Repair_status: true
}

const QueryReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.FETCH_DATA_QUERY_LODING: return { ...state, Query_loading: true, Query_data: [] };
        case actions.FETCH_DATA_QUERY_DATA: return { ...state, Query_loading: false, Query_data: action.value.data, Query_filters: action.value.filters }
        case actions.FETCH_DATA_QUERY_DELETE: return { ...state, Query_data: action.data.value, Query_filters: action.data.filters };

        case actions.FETCH_DATA_QUERY_COUNT_LODING: return { ...state, Count_data: true, Count_data: [] };
        case actions.FETCH_DATA_QUERY_COUNT_DATA: return { ...state, Count_loading: false, Count_data: action.value.count, Count_filters: action.value.filters }

        case actions.FETCH_DATA_QUERY_CALLBACK_DATA: return { ...state, Repair_status: state };

        default:
            return { ...state }
    }
}

export default QueryReducer