import React, { useState } from "react";
import CustomDialog from "../../../components/layouts/common/CustomDialog";
import {
  Box,
  Button,
  Grid,
  Grid2,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  CUSTOMER_TYPE,
  GENDER,
  LEVEL_OF_HEARING_LOSS,
  SNACK_BAR_VARIETNS,
  TYPE_OF_HEARING_LOSS,
  USER_ROLES,
} from "../../../utils/constants";
import { findObjectKeyByValue } from "../../../utils/main";
import moment from "moment";
import { Line } from "react-chartjs-2";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "../../../store/actions/modalAction";
import { callApiAction } from "../../../store/actions/commonAction";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import { updateCustomerApi } from "../../../apis/customer.api";
import StepperController from "../CustomerCreate/CustomerCreateController";

const ChartComponent = ({
  data,
  title,
  onValueChange,
  boneData = [],
  boneTitle = "",
  UclTitle = "",
  MclTitle = "",
  aidedData,
  UclData = [],
  MclData = [],
  rightEar,
  allowed_ac,
  allowed_bc,
  allowed_ucl,
  allowed_mcl,
  mode, // Pass the mode as a prop
  readonly = false, // Add readonly prop
}) => {
  const theme = useTheme();
  const labels = ["", "250", "500", "1k", "2k", "4k", "8k"];

  const dataFields = {
    labels,
    datasets: [
      ...(allowed_ac
        ? [
          {
            label: title,
            data: data.map((item) => (item !== null ? item : null)),
            pointBackgroundColor: "white",
            pointStyle: rightEar ? "circle" : "crossRot",
            hoverBorderColor: rightEar ? "#C24747" : "#1265A1",
            borderColor: rightEar ? "#C24747" : "#1265A1",
            radius: rightEar ? 4 : 7,
            tension: 0,
          },
        ]
        : []),
      ...(allowed_bc
        ? [
          {
            label: boneTitle,
            data: boneData.map((item) => (item !== null ? item : null)),
            pointBackgroundColor: "white",
            pointStyle: rightEar ? "circle" : "crossRot",
            hoverBorderColor: rightEar ? "#C24747" : "#1265A1",
            borderColor: rightEar ? "#C24747" : "#1265A1",
            radius: rightEar ? 4 : 7,
            tension: 0,
          },
        ]
        : []),
      ...(allowed_ucl
        ? [
          {
            label: UclTitle,
            data: UclData.map((item) => (item !== null ? item : null)),
            pointBackgroundColor: "white",
            pointStyle: rightEar ? "circle" : "crossRot",
            hoverBorderColor: rightEar ? "#C24747" : "#1265A1",
            borderColor: rightEar ? "#C24747" : "#1265A1",
            radius: rightEar ? 4 : 7,
            tension: 0,
          },
        ]
        : []),
    ],
  };

  return (
    <Line
      width="100%"
      height="100%"
      options={{
        interaction: readonly
          ? undefined
          : {
            mode: "nearest",
            intersect: false,
          },
        plugins: {
          datalabels: {
            display: 0,
            backgroundColor: "red",
          },
          tooltip: {
            enabled: readonly,
          },
          legend: {
            display: false,
          },
        },
        animation: false,
        elements: {
          point: {
            radius: 0,
          },
          line: {
            borderWidth: 1,
            tension: 0,
          },
        },
        scales: {
          y: {
            title: {
              display: true,
              text: "Threshold",
              color: "#3C508B",
              font: {
                size: 10,
              },
            },
            min: -10,
            max: 120,
            reverse: "true",
            ticks: {
              callback: (value) => value,
              font: {
                size: 10,
              },
              stepSize: 10,
            },
          },
          x: {
            title: {
              display: true,
              text: "Frequency",
              color: "#3C508B",
              font: {
                size: 10,
              },
            },
            ticks: {
              font: {
                size: 10,
              },
            },
          },
        },
        onClick: readonly
          ? undefined
          : (e, ele, chart) => {
            const xValue = chart.scales.x.getValueForPixel(e.x);
            const yValue = chart.scales.y.getValueForPixel(e.y);
            const nearestIndex = Math.round(xValue);

            if (nearestIndex >= 0 && nearestIndex < labels.length) {
              const newValue = Math.round(yValue / 5) * 5;
              onValueChange(nearestIndex, newValue, mode);
            }
          },
      }}
      data={dataFields}
    />
  );
};

const CustomerInformation = ({ params, valueref }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state);
  console.log("this is params", params);

  const isExpired =
    moment(new Date()).diff(params?.sold_details?.date, "day") > 14;

  const returnDeviceFun = (e, returnDate) => {
    e.preventDefault();
    console.log("this is return fun");
    setLoading(true);
    dispatch(
      callApiAction(
        async () => {
          await updateCustomerApi({
            id: params._id,
            customer_type: CUSTOMER_TYPE.RETURN,
            sold_details: {
              ...params.sold_details,
              product: params.sold_details.product._id,
              sales_person: params.sold_details.sales_person._id,
              status: CUSTOMER_TYPE.RETURN,
              date: returnDate,
            },
          });
        },
        () => {
          setLoading(false);
          dispatch(closeModal("messagedialogdelete"));
          dispatch(callSnackBar("Return", SNACK_BAR_VARIETNS.suceess));
        },
        (err) => {
          setLoading(false);
          // dispatch(closeModal("messagedialogdelete"));
        }
      )
    );
  };


  const purchase = (value) => {
    dispatch(
      openModal(
        <StepperController
          id={params._id}
          updatevalue={value}
          valueref={valueref}
        // callBack={(response, updatedData) => {
        //   setParams({ ...params, ...updatedData });
        // }}
        />,
        "sm", false, "CustomerInformation"
      )
    );
  };

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [rightAcData, setRightAcData] = useState([
    params.rf1,
    params.rf2,
    params.rf3,
    params.rf4,
    params.rf5,
    params.rf6,
    params.rf7,
    params.rf8,
  ]); // Initialize right AC data with null values
  const [rightBcData, setRightBcData] = useState([
    params.brf1,
    params.brf2,
    params.brf3,
    params.brf4,
    params.brf5,
    params.brf6,
    params.brf7,
    params.brf8,
  ]); // Initialize right BC data with null values
  const [rightUclData, setRightUclData] = useState([
    params.urf1,
    params.urf2,
    params.urf3,
    params.urf4,
    params.urf5,
    params.urf6,
    params.urf7,
    params.urf8,
  ]); // Initialize right BC data with null values
  const [rightMclData, setRightMclData] = useState([
    params.mrf1,
    params.mrf2,
    params.mrf3,
    params.mrf4,
    params.mrf5,
    params.mrf6,
    params.mrf7,
    params.mrf8,
  ]); // Initialize right BC data with null values
  const [leftAcData, setLeftAcData] = useState([
    params.lf1,
    params.lf2,
    params.lf3,
    params.lf4,
    params.lf5,
    params.lf6,
    params.lf7,
    params.lf8,
  ]); // Initialize left AC data with null values
  const [leftUclData, setLeftUclData] = useState([
    params.ulf1,
    params.ulf2,
    params.ulf3,
    params.ulf4,
    params.ulf5,
    params.ulf6,
    params.ulf7,
    params.ulf8,
  ]); // Initialize left AC data with null values
  const [leftBcData, setLeftBcData] = useState([
    params.blf1,
    params.blf2,
    params.blf3,
    params.blf4,
    params.blf5,
    params.blf6,
    params.blf7,
    params.blf8,
  ]); // Initialize left BC data with null values
  const [leftMclData, setLeftMclData] = useState([
    params.mlf1,
    params.mlf2,
    params.mlf3,
    params.mlf4,
    params.mlf5,
    params.mlf6,
    params.mlf7,
    params.mlf8,
  ]);
  return (
    <Box>
      <Box>
        <Grid2 container spacing={2} size={12}>
          <Grid2
            size={6}
            sx={{ border: "1px solid #101536", borderRadius: "8px" }}
          >
            <Box
              p={2}
              sx={{
                borderBottom: "1px solid #101536",
                backgroundColor: "#F6FAFF",
                borderRadius: "8px 8px 0 0",
              }}
            >
              <Typography fontWeight={800} variant="h3">
                Customer Details{" "}
              </Typography>
            </Box>
            <Grid2 container p={2} size={12}>
              <Grid2 item mt={2} size={2.5}>
                <Typography
                  fontWeight="bold"
                  variant="body1"
                  sx={{ color: "#808080" }}
                >
                  Full Name :{" "}
                </Typography>
              </Grid2>

              <Grid2 item mt={2} size={3.5}>
                <Typography
                  variant="body2"
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  {params.first_name ? params?.first_name : "NA"}{" "}
                  {params.middle_name ? params.middle_name : ""}{" "}
                  {params.last_name ? params.last_name : ""}
                </Typography>
              </Grid2>

              <Grid2 item mt={2} size={2.5}>
                <Typography
                  fontWeight="bold"
                  variant="body1"
                  sx={{ color: "#808080" }}
                >
                  Gender :{" "}
                </Typography>
              </Grid2>

              <Grid2 item mt={2} size={3.5}>
                <Typography
                  variant="body2"
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  {findObjectKeyByValue(params.gender, GENDER)}
                </Typography>
              </Grid2>
            </Grid2>

            <Grid2 container p={2} size={12}>
              <Grid2 item size={2.5}>
                <Typography
                  fontWeight="bold"
                  variant="body1"
                  sx={{ color: "#808080" }}
                >
                  Contact :
                </Typography>
              </Grid2>

              <Grid2 item size={3.5}>
                <Typography
                  variant="body2"
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  {params.phone ? params?.phone : "NA"}
                </Typography>
              </Grid2>

              <Grid2 item size={2.5}>
                <Typography
                  fontWeight="bold"
                  variant="body1"
                  sx={{ color: "#808080" }}
                >
                  DOB :{" "}
                </Typography>
              </Grid2>

              <Grid2 item size={3.5}>
                <Typography
                  variant="body2"
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  {moment(params.dob).format("DD/MM/YYYY")}
                </Typography>
              </Grid2>
            </Grid2>

            <Grid2 container p={2} size={12}>
              <Grid2 item size={2.5}>
                <Typography
                  fontWeight="bold"
                  variant="body1"
                  sx={{ color: "#808080" }}
                >
                  Email :{" "}
                </Typography>
              </Grid2>

              <Grid2 item size={3.5}>
                <Typography variant="body2" sx={{ wordBreak: "break-word" }}>
                  {params.email ? params?.email : "NA"}
                </Typography>
              </Grid2>

              <Grid2 item size={2.5}>
                <Typography
                  fontWeight="bold"
                  variant="body1"
                  sx={{ color: "#808080" }}
                >
                  Zip :{" "}
                </Typography>
              </Grid2>

              <Grid2 item size={3.5}>
                <Typography
                  variant="body2"
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  {params.zip_code ? params.zip_code : "NA"}
                </Typography>
              </Grid2>
            </Grid2>

            <Grid2 container p={2} size={12}>
              <Grid2 item size={2.5}>
                <Typography
                  fontWeight="bold"
                  variant="body1"
                  sx={{ color: "#808080" }}
                >
                  State :{" "}
                </Typography>
              </Grid2>

              <Grid2 item size={3.5}>
                <Typography
                  variant="body2"
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  {params.state ? params?.state : "NA"}
                </Typography>
              </Grid2>

              <Grid2 item size={2.5}>
                <Typography
                  fontWeight="bold"
                  variant="body1"
                  sx={{ color: "#808080" }}
                >
                  City :{" "}
                </Typography>
              </Grid2>

              <Grid2 item size={3.5}>
                <Typography
                  variant="body2"
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  {params.city ? params.city : "NA"}
                </Typography>
              </Grid2>
            </Grid2>

            <Grid2 container p={2} size={12}>
              <Grid2 item size={2.5}>
                <Typography
                  fontWeight="bold"
                  variant="body1"
                  sx={{ color: "#808080" }}
                >
                  Visit Date :{" "}
                </Typography>
              </Grid2>

              <Grid2 item size={3.5}>
                <Typography
                  variant="body2"
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  {moment(params?.createdAt).format("DD/MM/YYYY")}
                </Typography>
              </Grid2>

              {/* <Grid2 item size={2.5}>
                <Typography
                  fontWeight="bold"
                  variant="body1"
                  sx={{ color: "#808080" }}
                >
                  Sales Person :{" "}
                </Typography>
              </Grid2> */}

              {/* <Grid2 item size={3.5}>
                <Typography
                  variant="body2"
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  {params?.sales_person?.name
                    ? params?.sales_person?.name
                    : "NA"}
                </Typography>
              </Grid2> */}
            </Grid2>

            {/* <Grid2 container p={2} size={12}>
              <Grid2 item size={2.5}>
                <Typography
                  fontWeight="bold"
                  variant="body1"
                  sx={{ color: "#808080" }}
                >
                  Store Branch :{" "}
                </Typography>
              </Grid2>

              <Grid2 item size={9.5}>
                <Typography
                  variant="body2"
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  {params?.store_id?.name ? params?.store_id?.name : "NA"}
                </Typography>
              </Grid2>
            </Grid2> */}

            <Grid2 container p={2} size={12}>
              <Grid2 item size={2.5}>
                <Typography
                  fontWeight="bold"
                  variant="body1"
                  sx={{ color: "#808080" }}
                >
                  Address :
                </Typography>
              </Grid2>

              <Grid2 item size={9.5}>
                <Typography
                  variant="body2"
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  {params.address ? params?.address : "NA"}
                </Typography>
              </Grid2>
            </Grid2>
          </Grid2>

          <Grid2
            size={6}
            sx={{ border: "1px solid #101536", borderRadius: "8px" }}
          >
            <Box
              p={2}
              sx={{
                backgroundColor: "#F6FAFF",
                borderBottom: "1px solid #101536",
                borderRadius: "8px 8px 0 0",
              }}
            >
              <Typography fontWeight={800} variant="h4">
                Audiogram Details{" "}
              </Typography>
            </Box>

            <Grid2
              p={4}
              gap={2}
              container
              size={12}
              display={"flex"}
              justifyContent={"space-evenly"}
            >
              <Grid2 size={5}>
                <Box
                  sx={{
                    backgroundColor: "#FFF6F6",
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "10vh",
                    maxHeight: "25vh",
                    minWidth: "10vw",
                    maxWidth: "15vw",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      // width: "fit-content", // Matches the content width
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography color="error" variant="body1" ml={4}>
                      • Right
                    </Typography>
                  </Box>

                  {/* <Box
                    sx={{
                      width: "100%", // Ensures background width matches the chart's content
                    }}
                  > */}
                  <ChartComponent
                    allowed_ac={params?.allowed_ac}
                    allowed_bc={params?.allowed_bc}
                    allowed_ucl={params?.allowed_ucl}
                    allowed_mcl={params?.allowed_mcl}
                    data={rightAcData}
                    title="AC"
                    boneData={rightBcData}
                    boneTitle="BC"
                    UclData={rightUclData}
                    UclTitle="UCL"
                    MclData={rightMclData}
                    MclTitle="MCL"
                    rightEar
                    readonly={true}
                  // mode={mode}
                  />
                  {/* </Box> */}
                </Box>
              </Grid2>

              <Grid2 size={5}>
                <Box
                  sx={{
                    backgroundColor: "#F6FAFF",
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "10vh",
                    maxHeight: "25vh",
                    minWidth: "10vw",
                    maxWidth: "15vw",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography color="primary" variant="body1" ml={4}>
                      • Left
                    </Typography>
                  </Box>
                  <ChartComponent
                    allowed_ac={params?.allowed_ac}
                    allowed_bc={params?.allowed_bc}
                    allowed_ucl={params?.allowed_ucl}
                    allowed_mcl={params?.allowed_mcl}
                    data={leftAcData}
                    title="AC"
                    boneData={leftBcData}
                    boneTitle="BC"
                    UclData={leftUclData}
                    UclTitle="UCL"
                    MclData={leftMclData}
                    MclTitle="MCL"
                    readonly={true}
                  />
                </Box>
              </Grid2>

              <Grid2 container size={12} mt={2}>
                <Grid2 item size={isSmallScreen ? 3 : 3}>
                  <Typography variant="body1" color="primary">
                    Type Of Loss :
                  </Typography>
                </Grid2>
                <Grid2 item size={isSmallScreen ? 3 : 3}>
                  <Typography>
                    {findObjectKeyByValue(
                      params?.type_of_hearing_loss,
                      TYPE_OF_HEARING_LOSS
                    )}
                  </Typography>
                </Grid2>
                <Grid2 item size={isSmallScreen ? 3 : 3}>
                  <Typography variant="body1" color="primary">
                    Level Of Loss :
                  </Typography>
                </Grid2>
                <Grid2 item size={isSmallScreen ? 3 : 3}>
                  <Typography>
                    {findObjectKeyByValue(
                      params?.level_of_hearing_loss,
                      LEVEL_OF_HEARING_LOSS
                    )}
                  </Typography>
                </Grid2>
              </Grid2>
              <Grid2 container size={12}>
                <Box>
                  <Typography>Suggested Products : </Typography>
                </Box>

                {params?.suggested_product?.length ? (
                  <Box
                    ml={2}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      color: "#808080",
                    }}
                  >
                    {params?.suggested_product?.map((item) => (
                      <Box
                        p={1}
                        sx={{
                          border: "1px solid #28B867",
                          borderRadius: "5px",
                          backgroundColor: "#EAFAF1",
                          marginRight: 2,
                        }}
                      >
                        <Typography color="#28B867" variant="h6">
                          {item?.product_name}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <Box
                    ml={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      color: "#808080",
                    }}
                  >
                    <Box
                      // p={4}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        textAlign={"center"}
                        color="#28B867"
                        variant="h6"
                      >
                        No Product.
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      </Box>

      <Grid2 mt={2}  container spacing={2} size={12}>

        {(params?.customer_type == CUSTOMER_TYPE.PURCHASED ||
        params?.customer_type == CUSTOMER_TYPE.TRIALS ||
          params?.customer_type == CUSTOMER_TYPE.RETURN) && (
            <>
              <Grid2
                sx={{ border: "1px solid #101536", borderRadius: "8px" }}
                size={6}
                height={"28vh"}
              >
                <Grid2>
                  <Box
                    p={2}
                    sx={{
                      borderBottom: "1px solid #101536",
                      backgroundColor: "#F6FAFF",
                      borderRadius: "8px 8px 0 0",
                    }}
                  >
                    <Typography fontWeight={800} variant="h4">
                      {params?.customer_type == CUSTOMER_TYPE.PURCHASED
                        ? "Purchased Product Details"
                        : params?.customer_type == CUSTOMER_TYPE.TRIALS ? "Trial Product Details" : "Returned Product Details"}
                    </Typography>
                  </Box>
                </Grid2>

                <Box p={4}>
                  <Grid2 container size={12}>
                    {/* <Grid2 item size={2}></Grid2> */}
                    <Grid2 item size={4}>
                      <Typography
                        fontWeight="bold"
                        variant="body1"
                        sx={{
                          color: "#808080",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        Device :{" "}
                      </Typography>
                    </Grid2>
                    <Grid2 item size={4}>
                      <Typography
                        color="primary"
                        variant="h5"
                        sx={{
                          color: "#28B867",
                          borderRadius: "5px",
                        }}
                      >
                        {params?.sold_details?.product?.product_name}
                      </Typography>
                    </Grid2>
                  </Grid2>

                  <Grid2 container size={12}>
                    <Grid2 item size={4}>
                      <Typography
                        fontWeight="bold"
                        variant="body1"
                        sx={{
                          color: "#808080",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        Customer Type :{" "}
                      </Typography>
                    </Grid2>
                    {/* <Grid2 item size={2}></Grid2> */}
                    <Grid2 item size={4}>
                      <Typography
                        // variant="body1"
                        color="primary"
                        variant="h5"
                        sx={{
                          color: "#28B867",
                          borderRadius: "5px",
                        }}
                      >
                        {findObjectKeyByValue(params?.customer_type,CUSTOMER_TYPE) ?? "NA"}
                      </Typography>
                    </Grid2>
                  </Grid2>

                  <Grid2 mt={1} container size={12}>
                    {/* <Grid2 item size={2}></Grid2> */}
                    <Grid2 item size={4}>
                      <Typography
                        fontWeight="bold"
                        variant="body1"
                        sx={{
                          color: "#808080",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        Box Id :{" "}
                      </Typography>
                    </Grid2>
                    {/* <Grid2 item size={2}></Grid2> */}
                    <Grid2 item size={4}>
                      <Typography
                        variant="body1"
                        sx={{ display: "flex", justifyContent: "flex-start" }}
                      >
                        {params?.sold_details?.box_id
                          ? params?.sold_details?.box_id
                          : "NA"}
                      </Typography>
                    </Grid2>
                  </Grid2>

                  <Grid2 mt={1} container size={12}>
                    {/* <Grid2 item size={2}></Grid2> */}
                    <Grid2 item size={4}>
                      <Typography
                        fontWeight="bold"
                        variant="body1"
                        sx={{
                          color: "#808080",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        {params?.customer_type == CUSTOMER_TYPE.PURCHASED
                          ? "Purchase Date :"
                          : params?.customer_type == CUSTOMER_TYPE.TRIALS ? "Trial Date :" : "Return Date :"}
                      </Typography>
                    </Grid2>
                    {/* <Grid2 item size={2}></Grid2> */}
                    <Grid2 item size={4}>
                      <Typography
                        variant="body1"
                        sx={{ display: "flex", justifyContent: "flex-start" }}
                      >
                        {moment(params?.sold_details?.date).format("DD/MM/YYYY")}
                      </Typography>
                    </Grid2>
                  </Grid2>

                  <Grid2 mt={1} container size={12}>
                    {/* <Grid2 item size={2}></Grid2> */}
                    <Grid2 item size={4}>
                      <Typography
                        fontWeight="bold"
                        variant="body1"
                        sx={{
                          color: "#808080",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        Amount :{" "}
                      </Typography>
                    </Grid2>
                    {/* <Grid2 item size={2}></Grid2> */}
                    <Grid2 item size={4}>
                      <Typography
                        variant="body1"
                        sx={{ display: "flex", justifyContent: "flex-start" }}
                      >
                        {params?.sold_details?.amount
                          ? params?.sold_details?.amount
                          : "NA"}
                      </Typography>
                    </Grid2>
                  </Grid2>
                </Box>
              </Grid2>
            </>
          )}

          
      </Grid2>
    </Box >
  );
};

export default CustomerInformation;
