import { fetchProductApi } from "../../apis/product.api";
import { fetchColorApi } from "../../apis/productColor.api";
import { fetchTypeApi } from "../../apis/productType.api";
import { actions } from "../../utils/constants"
import { callApiAction } from "./commonAction";

export const fetchProductAction = (filters, onSuccess = () => { }, onError = () => { }) => {
    console.log("FILTEERSSS", filters)
    return async (dispatch, getState) => {
        dispatch({ type: actions.FETCH_DATA_PRODUCT_LODING });
        dispatch(callApiAction(
            async () => await fetchProductApi(filters),
            (response) => {
                dispatch({
                    type: actions.FETCH_DATA_PRODUCT_DATA,
                    value: { data: response, filters: filters }
                });
                onSuccess(response);
            },
            (err) => {
                onError(err);
            }
        ))
    }
}

export const deleteProductAction = (data, filters) => {
    return async (dispatch, getState) => {
        dispatch({
            type: actions.FETCH_DATA_PRODUCT_DELETE,
            value: { data: data, filters: filters }
        });
    }
}

export const deleteUpdateStockProductAction = (data, filters) => {
    return async (dispatch, getState) => {
        dispatch({
            type: actions.FETCH_DATA_UPDATE_STOCK_PRODUCT_DELETE,
            value: { data: data, filters: filters }
        });
    }
}








export const fetchProductColorAction = (filters, onSuccess = () => { }, onError = () => { }) => {
    return async (dispatch, getState) => {
        dispatch({ type: actions.FETCH_DATA_PRODUCT_COLOR_LODING });
        dispatch(callApiAction(
            async () => await fetchColorApi(filters),
            (response) => {
                dispatch({
                    type: actions.FETCH_DATA_PRODUCT_COLOR_DATA,
                    value: { data: response, filters: filters }
                });
                onSuccess(response);
            },
            (err) => {
                onError(err);
            }
        ))
    }
}

export const deleteProductColorAction = (data, filters) => {
    return async (dispatch, getState) => {
        dispatch({
            type: actions.FETCH_DATA_PRODUCT_COLOR_DELETE,
            value: { data: data, filters: filters }
        });
    }
}

export const fetchProductTypeAction = (filters, onSuccess = () => { }, onError = () => { }) => {
    return async (dispatch, getState) => {
        dispatch({ type: actions.FETCH_DATA_PRODUCT_TYPE_LODING });
        dispatch(callApiAction(
            async () => await fetchTypeApi(filters),
            (response) => {
                dispatch({
                    type: actions.FETCH_DATA_PRODUCT_TYPE_DATA,
                    value: { data: response, filters: filters }
                });
                onSuccess(response);
            },
            (err) => {
                onError(err);
            }
        ))
    }
}

export const deleteProductTypeAction = (data, filters) => {
    return async (dispatch, getState) => {
        dispatch({
            type: actions.FETCH_DATA_PRODUCT_TYPE_DELETE,
            value: { data: data, filters: filters }
        });
    }
}