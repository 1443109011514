import React, { useEffect, useState } from "react";
import CustomerInformation from "./CustomerInformation";
import CustomDialog from "../../../components/layouts/common/CustomDialog";
import { callApiAction } from "../../../store/actions/commonAction";
import { useDispatch } from "react-redux";
import { getCustomerByIdApi } from "../../../apis/customer.api";
import { Box } from "@mui/material";
import loadingImg from "../../../assets/images/loading.gif";
import SubmitButton from "../../../components/button/SubmitButton";
import { openModal } from "../../../store/actions/modalAction";
import { CUSTOMER_TYPE } from "../../../utils/constants";
import TrialProductController from "../CustomerCreate/TrialController/TrialProductController";
import PurchasedProductController from "../CustomerCreate/PurchasedController/PurchasedProductController";

const CustomerInformationController = ({ id,callBack }) => {
  const [params, setParams] = useState({});
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  console.log("paramsss",params)
  const onTrialProduct = (e) =>{
    e.preventDefault()
      dispatch(openModal(<TrialProductController customer_type={CUSTOMER_TYPE.TRIALS} id={id} callBack={callBack}/>,"md",false,"trial-product"))
  }

  const onPurchaseProduct = () =>{
    dispatch(openModal(<PurchasedProductController customer_type={CUSTOMER_TYPE.PURCHASED} id={id} callBack={callBack}/>,"md",false,"purchased-product"))
  }

  const fetchDetails = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getCustomerByIdApi({ id }),
        (response) => {
          setParams(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (id) {
      fetchDetails();
    }
  }, [id]);

  return (
    <CustomDialog id={"CustomerInformation"} title={`Customer Information`}>
      {!loading ? (
        <>
        {/* disabled={params.customer_type == CUSTOMER_TYPE.TRIALS}  */}
        {/* disabled={params.customer_type == CUSTOMER_TYPE.TRIALS}  */}
          <Box mb={2} sx={{display:"flex",gap:"5px",justifyContent:"flex-end",}}>
            <SubmitButton sx={{maxWidth:"200px"}}  title="Trial" onClick={(e)=>onTrialProduct(e)}/>
            <SubmitButton sx={{maxWidth:"200px"}}  title="Purchase" onClick={()=>onPurchaseProduct()}/>
          </Box>
          <CustomerInformation params={params} />
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={loadingImg} alt="Loading ..." />
        </Box>
      )}
    </CustomDialog>
  );
};

export default CustomerInformationController;
