import { Box } from "@mui/material";
import { getScpOrFpDataApi, productDashboardDataApi } from "../../../apis/dashboard.api";
import { USER_ROLES } from "../../../utils/constants";
import { useEffect } from "react";
import { callApiAction } from "../../../store/actions/commonAction";
import { useDispatch } from "react-redux";
import { useState } from "react"; import ProductListUi from "./ProductListUi";

const ProductListController = ({ role }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [dataList, setDataList] = useState([])


    const fetchData = () => {
        setLoading(true);
        dispatch(
            callApiAction(
                async () => await productDashboardDataApi({}),
                async (response) => {
                    setDataList(response)
                    setLoading(false);
                },
                (err) => {
                    setLoading(false);
                }
            )
        );
    };

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <ProductListUi
            dataList={dataList} role={role}
        />
    );
};
export default ProductListController;