import { useEffect, useState, useMemo, useRef, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Chip, IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import {
  findObjectKeyByValue,
  OrderColor,
  StatusColor,
  titleCase,
} from "../../utils/main";
import {
  CUSTOMER_TYPE,
  ORDER_STATUS,
  TRIALS_STATUS,
} from "../../utils/constants";
import {
  getSalesCountApi,
  getSalesDataApi,
  getSalesValueApi,
  updateSalesStatus,
} from "../../apis/sales.api";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal, openModal } from "../../store/actions/modalAction";
import TrialsListUi from "./TrialsListUi";
import { getTrialsCountApi, getTrialsDataApi } from "../../apis/trials.api";
import MessageDilog from "../../components/MessageDilog";
import { Check } from "@mui/icons-material";

const ActionComponent = ({
  callback,
  params,
  setParams,
  deleteApi,
  valueref,
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state);

  const doneFun = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await updateSalesStatus({
            id: params?.customer_id?._id,
            order_status: ORDER_STATUS.FULFILLED,
          }),
        (response) => {
          setParams({ ...params });
          callback();
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
    dispatch(closeModal());
  };

  const onFullFillFun = () => {
    dispatch(
      openModal(
        <MessageDilog
          onSubmit={doneFun}
          title="Alert!"
          message={`Are you sure to your status is mark as Fullfill ?`}
        />,
        "sm"
        // dispatch(callSnackBar("Topic Deleted Successfully..",SNACK_BAR_VARIETNS.suceess))
      )
    );
  };

  return (
    <Box sx={{ width: "100%", alignItems: "center", display: "flex" }}>
      {params?.customer_id?.sold_details?.order_status ==
        ORDER_STATUS.PENDING && (
        <Tooltip title="Fulfilled">
          <IconButton size="inherit" onClick={onFullFillFun}>
            <Check color="success" fontSize="inherit" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};
const TrialsListController = ({ userId, isAccordion = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});
  const [salesValue, setSalesValue] = useState(0);

  const [selectTrialsType, setSelectTrialsType] = useState(CUSTOMER_TYPE.ALL);
  const [count, setCount] = useState([
    {
      count: 0,
      status: TRIALS_STATUS.ALL,
      _id: 0,
    },
    {
      count: 0,
      status: TRIALS_STATUS.ACTIVE,
      _id: 1,
    },
    {
      count: 0,
      status: TRIALS_STATUS.RETURN,
      _id: 2,
    },
  ]);
  const [countLoading, setCountLoading] = useState(false);
  const [filters, setFilters] = useState({
    userId,
    pageNo: 1,
    pageSize: 10,
    searchable: ["customer_id.first_name", "last_name", "product_name", "box_id"],
    search: "",
    role: "",
    sort: "createdAt",
    sortDirection: -1,
    startDate: moment().startOf("day").valueOf(),
    endDate: moment().endOf("day").valueOf(),
    deleted: false,
  });
  const fetchcountApi = getTrialsCountApi;
  const fetchDataApi = getSalesDataApi;
  const fetchSalesValue = getSalesValueApi;
  //   const deleteApi = deleteCustomerApi;
  const title = "Customers";

  const columns = useMemo(() => {
    return [
      {
        id: 1,
        fieldName: "date",
        label: "Date",
        align: "left",
        sort: true,
        renderValue: (params) =>
          moment(params?.sold_details?.date).format("DD/MM/YYYY"),
      },
      {
        id: 2,
        fieldName: "product_name",
        label: "Name",
        align: "left",
        sort: true,
        renderValue: (params) =>
          params?.customer_id?.first_name +
          " " +
          params?.customer_id?.last_name,
      },
      {
        id: 3,
        fieldName: "product",
        label: "Product",
        align: "left",
        renderValue: (params) => params?.product_id?.product_name,
      },
      {
        id: 4,
        fieldName: "box_id",
        label: "Device Id",
        align: "left",
      },
      {
        id: 5,
        fieldName: "status",
        label: "Trial Fill",
        align: "left",
        sort: true,
        renderValue: (params) => {
          return (
            <Chip
              size="small"
              key={params?.customer_id?.sold_details?.order_status}
              color={OrderColor(
                params?.customer_id?.sold_details?.order_status
              )}
              label={titleCase(
                findObjectKeyByValue(
                  params?.customer_id?.sold_details?.order_status,
                  ORDER_STATUS
                )
              )}
            />
          );
        },
      },
      {
        id: 5,
        fieldName: "",
        label: "Action",
        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            params={params}
            setParams={setParams}
            callback={() => {
              fetchTrialsDataFun();
              fetchTrialsCount();
            }}
          />
        ),
      },
    ];
  }, [dispatch]);

  const fetchTrialsDataFun = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await getTrialsDataApi({ ...filters, status: selectTrialsType }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  const fetchTrialsCount = () => {
    setCountLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchcountApi({ ...filters }),
        (response) => {
          setCount(response);
          setCountLoading(false);
        },
        (err) => {
          setCountLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchTrialsDataFun();
  }, [filters, selectTrialsType]);

  useEffect(() => {
    fetchTrialsCount();
  }, [filters.startDate, filters.endDate]);

  return (
    <TrialsListUi
      salesValue={salesValue}
      columns={columns}
      list={list}
      filters={filters}
      setFilters={setFilters}
      count={count}
      setSelectTrialsType={setSelectTrialsType}
      selectTrialsType={selectTrialsType}
    />
  );
};
export default memo(TrialsListController);
