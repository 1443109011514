export const USER_ROLES = {
  WEHEAR_ADMIN: 1,
  SALES_CHANNEL_PARTNER: 2,
  FRANCHISE_PARTNER: 3,
  // FRANCHISE_STAFF: 4,
  SALES_STAFF: 5,
};
export const actions = {
  SIGN_IN: "SIGN_IN",
  SET_USER: "SET_USER",
  SIGN_OUT: "SIGN_OUT",
  OPEN_MODAL: "OPEN_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",
  UPDATE_USER_DATA: "UPDATE_USER_DATA",
  SET_USER_LAST_READ_NOTIFICATION: "SET_USER_LAST_READ_NOTIFICATION",
  CALL_SNACKBAR_DATA: "CALL_SNACKBAR_DATA",
  SET_NOTIFICATION_DATA: "SET_NOTIFICATION_DATA",
  NOTIFICATION_READ: "NOTIFICATION_READ",

  SET_INVENTORY_COUNT_DATA: "SET_INVENTORY_COUNT_DATA",
  SET_INVENTORY_COUNT_FILTERS: "SET_INVENTORY_COUNT_FILTERS",
  START_INVENTORY_COUNT_LOADING: "START_INVENTORY_COUNT_LOADING",
  SET_INVENTORY_LIST: "SET_INVENTORY_LIST",
  UPDATE_INVENTORY_LIST: "UPDATE_INVENTORY_LIST",

  FETCH_DATA_PRODUCT_LODING: "FETCH_DATA_PRODUCT_LODING",
  FETCH_DATA_PRODUCT_DATA: "FETCH_DATA_PRODUCT_DATA",
  FETCH_DATA_PRODUCT_COUNT_LODING: "FETCH_DATA_PRODUCT_COUNT_LODING",
  FETCH_DATA_PRODUCT_COUNT: "FETCH_DATA_PRODUCT_COUNT",
  FETCH_DATA_PRODUCT_DELETE: "FETCH_DATA_PRODUCT_DELETE",

  FETCH_DATA_UPDATE_STOCK_PRODUCT_LODING:
    "FETCH_DATA_UPDATE_STOCK_PRODUCT_LODING",
  FETCH_DATA_UPDATE_STOCK_PRODUCT_DATA: "FETCH_DATA_UPDATE_STOCK_PRODUCT_DATA",
  FETCH_DATA_UPDATE_STOCK_PRODUCT_DELETE:
    "FETCH_DATA_UPDATE_STOCK_PRODUCT_DELETE",

  FETCH_DATA_PRODUCT_COLOR_LODING: "FETCH_DATA_PRODUCT_COLOR_LODING",
  FETCH_DATA_PRODUCT_COLOR_DATA: "FETCH_DATA_PRODUCT_COLOR_DATA",
  FETCH_DATA_PRODUCT_COLOR_DELETE: "FETCH_DATA_PRODUCT_COLOR_DELETE",

  FETCH_DATA_QUERY_LODING: "FETCH_DATA_QUERY_LODING",
  FETCH_DATA_QUERY_DATA: "FETCH_DATA_QUERY_DATA",
  FETCH_DATA_QUERY_DELETE: "FETCH_DATA_QUERY_DELETE",

  FETCH_DATA_QUERY_COUNT_LODING: "FETCH_DATA_QUERY_COUNT_LODING",
  FETCH_DATA_QUERY_COUNT_DATA: "FETCH_DATA_QUERY_COUNT_DATA",

  FETCH_DATA_QUERY_CALLBACK_DATA: "FETCH_DATA_QUERY_CALLBACK_DATA",

  FETCH_DATA_PRODUCT_TYPE_LODING: "FETCH_DATA_PRODUCT_TYPE_LODING",
  FETCH_DATA_PRODUCT_TYPE_DATA: "FETCH_DATA_PRODUCT_TYPE_DATA",
  FETCH_DATA_PRODUCT_TYPE_DELETE: "FETCH_DATA_PRODUCT_TYPE_DELETE",

  SET_DEVICES_LIST: "SET_DEVICES_LIST",
  UPDATE_DEVICES_LIST: "UPDATE_DEVICES_LIST",
  REMOVE_DEVICE_LIST: "REMOVE_DEVICE_LIST",
  SET_ERRORS: "SET_ERRORS",
};

export const NOTIFICATION_TYPE = {
  general: "general",
  lead: "lead",
};

export const PROBLEMS = [
  { label: "Device not switching on", value: 0 },
  { label: "Lower sound of any side of OX", value: 1 },
  { label: "Connectivity issue", value: 2 },
  { label: "Mic not working", value: 3 },
  { label: "Beep sound", value: 4 },
  { label: "Battery discharging", value: 5 },
  { label: "Battery ", value: 6 },
  { label: "Other", value: 7 },
];

export const SOLUTIONS = {
  CALL: 1,
  VIDEO_CALL: 2,
  REPAIR: 3,
};

export const DEVICE_STATUS = {
  AVAILABLE: 0,
  IN_STORE: 1,
  ON_TRIALS: 2,
  ALL: 3,
  // PURCHASED: 3,
  // REFUND: 4,
};

// export const REPAIRING_STATUS={
//   PENDING:1,
//   WORKING_PROGRESS:2,
//   COMPLETED:3,
// }

export const YES_NO = {
  YES: true,
  NO: false,
};

export const SEND_STATUS = {
  PENDING: 1,
  ARRIVED: 2,
  PENDING_TO_SEND: 3,
};

export const SEND_STATUS_NAME = {
  "Yet TO RECEIVE": 1,
  "RECEIVED BY WEHEAR": 2,
  "PENDING TO SEND": 3,
};

export const RECEIVED_STATUS_NAME = {
  "PENDING FROM STORE": 1,
  "RECEIVED FROM STORE": 2,
  "PENDING TO SEND": 3,
};

export const SNACK_BAR_VARIETNS = {
  error: "error",
  suceess: "success",
  info: "info",
  warning: "warning",
};

export const DAY_WEEK_MONTH = {
  DAY: "day",
  WEEK: "week",
};

export const SALEABLE_TYPE = {
  SOLD: 3,
  TRIAL: 2,
  ALL: 4,
};

export const GENDER = {
  MALE: 1,
  FEMALE: 2,
  OTHER: 3,
};

export const CUSTOMER_TYPE = {
  ALL: 0,
  TRIALS: 1,
  PURCHASED: 2,
  RETURN: 3,
};

export const ORDER_STATUS = {
  ALL: 0,
  PENDING: 1,
  FULFILLED: 2
}

export const TRIALS_STATUS = {
  ALL: 0,
  ACTIVE: 1,
  RETURN: 2
}

export const REPAIRING_STATUS = {
  ALL: 8,
  QUERY_RAISE: 1,
  SEND_TO_WEHEAR: 2,
  REPAIRING_CONTINUE: 3,
  REPAIR_COMPLETED: 4,
  SEND_TO_STORE: 5,
  SEND_TO_CUSTOMER: 6,
};
export const INVOICE_TYPE = {
  TRIALS: 2,
  PURCHESED: 3,
  RETURN: 4,
};

export const QUERY_STATUS = {
  RETURN: 1,
  REPAIR: 2,
  REPLACEMENT: 3,
};

export const STOCKLOG_STATUS = {
  SELL: 1,
  RETURN: 2,
  REPLACEMENT: 3,
};

export const INVENTORY_TYPE = {
  SALEABLE: 1,
  TRIALS: 2,
  RETURN: 3,
  SOLD_WITH_REPLACEMENT: 4,
  RETURN_WITH_REPLACEMENT: 5,
};

export const STOCK_TYPE = {
  ALL: 1,
  ON_TRIAL: 2,
  IN_STORE: 3,
}

export const DATE_TIME_FILTERS = {
  this_week: "This Week",
  this_month: "This Month",
  this_year: "This Year",
  last_month: "Last Month",
  last_year: "Last Year",
  last_week: "Last Week",
  today: "Today",
  yesterday: "Yesterday",
};

export const PAYMENT_STATUS = {
  PENDING: 0,
  COMPLETED: 1,
};

export const STATUS_COLOR_TYPE = (currentstatus) => {
  switch (currentstatus) {
    case true:
      return "#1B4381";
    default:
      return "white";
  }
};

export const BUTTON_STATUS_COLOR_TYPE = (currentstatus) => {
  switch (currentstatus) {
    case true:
      return "contained";
    default:
      return "outlined";
  }
};

export const LEVEL_OF_HEARING_LOSS = {
  MILD: 1,
  MODERATE: 2,
  SEVERE: 3,
  PROFOUND: 4,
};

export const TYPE_OF_HEARING_LOSS = {
  CONDUCTIVE: 1,
  SN: 2,
  MIXED: 3,
};

export const PAYMENT_TYPE = {
  UPI: 1,
  CASH: 2,
  CARD: 3
}