import { useCallback, useMemo, useReducer, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import ChannelSaleCreateUi from "./ChannelSaleCreateUi"
import useValidate from "../../store/hooks/useValidator"
import InventoryListReducer from "../../store/reducers/InventoryListReducer"
import { closeModal, openModal } from "../../store/actions/modalAction"
import { fetchFindByBoxIdApi, transferProductStockApi } from "../../apis/productStock.api"
import { callSnackBar } from "../../store/actions/snackbarAction"
import { SNACK_BAR_VARIETNS } from "../../utils/constants"
import { callApiAction } from "../../store/actions/commonAction"

const ChannelSaleCreateController = ({ title, callBack = () => {}, defaultData, isFree }) => {
    const validate = useValidate()
    const params = useParams()
    const dispatch = useDispatch()
    const { inventoryCount, lifeStockCount } = useSelector(state => state)

    const [fields, setFields] = useState({
        amount: '',
        err: '',
        category: '',
        invoice_no: "",
        child_id: "",
        startBoxId: '',
        endBoxId: '',
        pageNo: 1,
        pageSize: 500,
        sort: "box_id",
        sortDirection: 1,
    });

    const [boxes, dispatchBoxes] = useReducer(InventoryListReducer, defaultData)
    const [loading, setLoading] = useState(false)

    const validationSchema = useMemo(() => ([
        {
            required: true,
            value: fields.startBoxId,
            field: 'Starting box Id ',
        },
        {
            required: true,
            value: fields.endBoxId,
            field: 'Last box Id',
        }
    ]), [fields])

    const onSelectRange = useCallback((e) => {
        e.preventDefault();
        const validationResponse = validate(validationSchema);
        if (validationResponse === true) {
            setLoading(true);
            dispatch(
                callApiAction(
                    async () => await fetchFindByBoxIdApi(fields,),
                    async (response) => {
                        const fetchedBoxes = response.result.map((box) => ({
                            box_id: box.box_id,
                            selected: true,
                        }));
                        dispatchBoxes({ type: 'SET_INVENTORY_LIST', data: fetchedBoxes });
                        setFields({ ...fields, err: '', startBoxId: '', endBoxId: '' });
                        setLoading(false);
                    },
                    (err) => {
                        setFields({ ...fields, err: err.message || 'Failed to fetch data.' });
                        dispatchBoxes({ type: 'CLEAR_BOXES' });
                        setLoading(false);
                    }
                )
            );
        } else {
            setFields({ ...fields, err: validationResponse });
        }
    }, [fields, validate]);

    const validationSchemaForFinaleSubmit = useMemo(() => ([
        {
            required: true,
            value: fields.invoice_no,
            field: 'Invoice number',
        },
        {
            required: true,
            value: boxes,
            isArray: true,
            field: 'Boxes ',
        }
    ]), [fields, boxes])

    const onSubmit = useCallback((e) => {
        e.preventDefault()
        const validationResponse = validate(validationSchemaForFinaleSubmit)
        if (validationResponse === true) {
            setLoading(true)
            const transferableData = {
                boxes: boxes.filter((val) => val.selected).map((val) =>
                    val.box_id),
                amount: fields.amount,
                child_id: fields.child_id,
                invoice_no: fields.invoice_no,
                category: fields.category
            }

            dispatch(
                callApiAction(
                    async () => await transferProductStockApi(transferableData),
                    async (response) => {
                     callBack()
                        dispatch(callSnackBar("Product Created Successfully", SNACK_BAR_VARIETNS.suceess))
                        dispatch(closeModal());
                        setLoading(false);
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, err: validationResponse })
        }
    }, [fields, boxes])

    return <ChannelSaleCreateUi
        isFree={isFree}
        boxes={boxes}
        dispatchBoxes={dispatchBoxes}
        setFields={setFields}
        loading={loading}
        fields={fields}
        title={title}
        onSubmit={onSubmit}
        onSelectRange={onSelectRange} />

}
export default ChannelSaleCreateController