import { Box } from "@mui/material";
import CountListUi from "./CountListUi";
import { dashboardCountDataApi } from "../../../apis/dashboard.api";
import { callApiAction } from "../../../store/actions/commonAction";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";

const CountListController = () => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({
        value_of_sales: 0,
        total_incentive: 0,
        total_customers: 0,
        total_trials: 0,
    })


    const fetchData = () => {
        setLoading(true);
        dispatch(
            callApiAction(
                async () => await dashboardCountDataApi(),
                async (response) => {
                    setData(response);
                    setLoading(false);
                },
                (err) => {
                    setLoading(false);
                }
            )
        );
    };

    useEffect(() => {
        fetchData();
    }, []);


    return (
        <CountListUi data={data} setData={setData} />
    );
};
export default CountListController;