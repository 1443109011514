import {
  Button,
  ButtonGroup,
  CircularProgress,
  Grid2,
  Paper,
  Skeleton,
  Typography,
  styled,
} from "@mui/material";
import { Box, useMediaQuery } from "@mui/system";
import { useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { memo, useState } from "react";
import ButtonComponentsUi from "../../components/button/ButtonComponentsUi";
import { findObjectKeyByValue, toTitleCase } from "../../utils/main";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxDesktop";
import {
  CUSTOMER_TYPE,
  DATE_TIME_FILTERS,
  ORDER_STATUS,
  TRIALS_STATUS,
} from "../../utils/constants";
import AsyncSearchBar from "../../components/inputs/AsyncSearchBar";
import DataTable from "../../components/tables/DataTable";
import TimeRangeSelector from "../../components/layouts/common/TimeRangeSelector";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  alignItems: "center",
}));

const TrialsListUi = ({
  title,
  filters,
  setFilters,
  loading,
  setSelectTrialsType,
  selectTrialsType,
  count,
  countLoading,
  isAccordion,
  columns,
  list,
  salesValue,
}) => {
  const user = useSelector((state) => state.user);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  return (
    <Paper elevation={0} sx={{ width: "100%", minHeight: "100%", padding: 3 }}>
      <Box>
        <FilterTitleBox>
          <Typography variant="h3" color={"#000"}>
            {title}
          </Typography>
        </FilterTitleBox>

        <Box>
          {countLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                height: "15vh",
              }}
            >
              {[0, 1, 2, 3]?.map((row) => (
                <Skeleton
                  key={row}
                  variant="rectangular"
                  width={"24%"}
                  height={120}
                  sx={{
                    borderRadius: "8px",
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  }}
                />
              ))}
            </Box>
          ) : (
            <Box
              mt={4}
              gap={4}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                height: isSmallScreen ? "10vh" : "15vh",
                width: "100%",
                alignItems: "center",
              }}
            >
              {count?.map((item) => (
                <ButtonComponentsUi
                  count={item?.count ?? 0}
                  onSubmit={() => setSelectTrialsType(item?.status)}
                  loading={loading}
                  colorType={selectTrialsType === item?.status}
                  Title={toTitleCase(
                    findObjectKeyByValue(item?.status, TRIALS_STATUS)
                  )}
                />
              ))}
            </Box>
          )}
        </Box>
      </Box>

      <Box sx={{ mt: 4 }}>
        <FilterTitleBox>
          <PaddingBoxInDesktop
            sx={{
              display: "flex",
              width: "30vw",
              flexDirection: "flex-start",
            }}
          >
            <TimeRangeSelector
              defaultVal={{
                label: DATE_TIME_FILTERS.today,
                _id: findObjectKeyByValue(
                  DATE_TIME_FILTERS.today,
                  DATE_TIME_FILTERS
                ),
              }}
              onChange={(val) => setFilters({ ...filters, ...val })}
            />
          </PaddingBoxInDesktop>

          <PaddingBoxInDesktop p={2} sx={{ display: "flex", width: "20vw" }}>
            <AsyncSearchBar
              fullWidth
              title="Search Name | Email"
              size="small"
              placeholder={"Search Name | Email"}
              defaultValue={filters?.search}
              onChange={(changedVal) =>
                setFilters({
                  ...filters,
                  pageNo: 1,
                  pageSize: 10,
                  search: changedVal,
                })
              }
            />
          </PaddingBoxInDesktop>
        </FilterTitleBox>

        <Box mt={2}>
          <DataTable
            key={JSON.stringify(list)}
            columns={columns}
            rows={list?.result ? list.result : []}
            count={list?.total ?? 0}
            filters={filters}
            setFilters={setFilters}
            loading={loading}
          />
        </Box>
      </Box>
    </Paper>
  );
};
export default memo(TrialsListUi);
