import { Box, ButtonGroup, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import DataTable from "../../components/tables/DataTable";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { fetchDemoUnitsInfoApi, fetchProductWiseInventory } from "../../apis/inventory.api";
import { DEVICE_STATUS, STATUS, INVOICE_TYPE, IN_STORE_TYPE } from "../../utils/constants";
import { findObjectKeyByValue, titleCase } from "../../utils/main";
import ButtonComponentsUi from "../../components/button/ButtonComponentsUi";
import { fetchDemoUnitsApi } from "../../apis/inventory.api";

const DemoUnitProductWiseDataController = ({ id, parent_id, idd }) => {
    const dispatch = useDispatch();
    const [list, setList] = useState({});
    const { user } = useSelector((state) => state)
    const [loading, setLoading] = useState(true);
    const [buttonStatus, setButtonStatus] = useState(DEVICE_STATUS.ALL)

    const columns = useMemo(() => {
        return [
            {
                id: 1,
                minWidth: "50px",
                fieldName: "product_name",
                label: "Product",
                align: "left",
                sort: true,
                renderValue: (params) => params?.product_name,
            },
            {
                id: 2,
                minWidth: "50px",
                fieldName: "box_id",
                label: "Box Id",
                align: "left",
                sort: true,
                renderValue: (params) => params?.box_id,
            },
            {
                id: 3,
                fieldName: "category",
                label: "Type",
                align: "left",
                sort: true,
                renderValue: (params) => findObjectKeyByValue(params?.device_status, DEVICE_STATUS)
            },
        ];
    }, [dispatch]);
    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: "",
        searchable: ["name"],
        sort: "",
        sortDirection: -1,
    });

    const fetchList = () => {
        setLoading(true);
        dispatch(
            callApiAction(
                async () =>
                    await fetchDemoUnitsInfoApi({ user_Id: id || user.data._id, product_id: idd, buttonStatus, ...filters }),
                (response) => {
                    setList(response);
                    console.log("this is open model response", response);
                    setLoading(false);
                },
                (err) => {
                    setLoading(false);
                }
            )
        );
    };
    console.log("object id preyash", idd);

    useEffect(() => {
        if (idd) {
            fetchList();
        }
    }, [buttonStatus, filters]);

    return (
        <CustomDialog id={"productstock"} title={"Product Information"}>
            <Box width={"100%"} mb={2}>
                <ButtonGroup fullWidth sx={{ flexDirection: "row" }}>
                    <ButtonComponentsUi
                        width={500}
                        onSubmit={() => setButtonStatus(DEVICE_STATUS.ALL)}
                        ButtonGroup
                        STATUSWiseData={buttonStatus === DEVICE_STATUS.ALL}
                        Title={"ALL"}
                    />
                    <ButtonComponentsUi
                        onSubmit={() => setButtonStatus(DEVICE_STATUS.IN_STORE)}
                        ButtonGroup
                        STATUSWiseData={buttonStatus === DEVICE_STATUS.IN_STORE}
                        Title={titleCase(
                            findObjectKeyByValue(DEVICE_STATUS.IN_STORE, DEVICE_STATUS)
                        )}
                    />
                    <ButtonComponentsUi
                        onSubmit={() => setButtonStatus(DEVICE_STATUS.ON_TRIALS)}
                        ButtonGroup
                        STATUSWiseData={buttonStatus === DEVICE_STATUS.ON_TRIALS}
                        Title={titleCase(
                            findObjectKeyByValue(DEVICE_STATUS.ON_TRIALS, DEVICE_STATUS)
                        )}
                    />
                </ButtonGroup>
            </Box>

            <Box minHeight={"30vh"}>
                <DataTable
                    columns={columns}
                    rows={list?.result ? list?.result : []}
                    count={list?.total ?? 0}
                    filters={filters}
                    setFilters={setFilters}
                    loading={loading}
                />
            </Box>
        </CustomDialog>
    );
};

export default DemoUnitProductWiseDataController;