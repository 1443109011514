import { Box } from "@mui/material";
import ScpListUi from "./ScpListUi";
import { getScpOrFpDataApi } from "../../../apis/dashboard.api";
import { USER_ROLES } from "../../../utils/constants";
import { useEffect } from "react";
import { callApiAction } from "../../../store/actions/commonAction";
import { useDispatch } from "react-redux";
import { useState } from "react";

const ScpListController = ({ role }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);

  const fetchData = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await getScpOrFpDataApi({ role: role }),
        async (response) => {
          setDataList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  return <ScpListUi dataList={dataList} role={role} />;
};
export default ScpListController;
