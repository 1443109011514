import { useState } from "react"
import { useParams } from "react-router-dom"
import Papa from "papaparse";
import useValidate from "../../store/hooks/useValidator";
import { useDispatch } from "react-redux";
import { closeModal } from "../../store/actions/modalAction";
import { callApiAction } from "../../store/actions/commonAction";
import ImportCsvCreateUi from "./ImportCsvCreateUi";
import { importProductStockCsvApi } from "../../apis/productStock.api";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { SNACK_BAR_VARIETNS } from "../../utils/constants";

const ImportCsvCreateController = ({ product_id }) => {
  const params = useParams()
  const validate = useValidate()
  const dispatch = useDispatch()
  const [err, setErr] = useState("");
  const [hasErr, setHasErr] = useState(false);
  const [data, setData] = useState([]);
  const [file, setFile] = useState(null);
  const [fileErr,setFileErr] = useState("")

  const fields = [
    "box_id",
    "product_id",
  ]
  const [loading, setLoading] = useState()
  const onFileSelect = (e) => {

    setLoading(true)
    const file = e.target.files[0]
    setFile(e.target.files[0])
    const reader = new FileReader();

    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: true });
      const parsedData = csv?.data;
      const columns = Object.keys(parsedData[0]);
      let hasColumnError = false;

      for (let field of fields) {
        if (!columns.includes(field)) {
          setErr(`Field with field name ${field} doesnot exist.`);
          setLoading(false);
          hasColumnError = true;
          break;
        }
      }

      if (parsedData && Array.isArray(parsedData)) {
        const tempData = parsedData?.map((parsedrow) => {
          if (Object.keys(parsedrow).length === columns.length) {
            const rowData = {
              hasError: false,
              data: {},
            };
            const row = {};
            for (let cell in parsedrow) {
              if (
                (cell == "box_id" || cell == "box_id") &&
                parsedrow[cell] == ""
              ) {
                rowData["hasError"] = true;
                setHasErr(true);
                row[cell] = {
                  value: parsedrow[cell],
                  err: "This cell should be non empty.",
                };
              } else if (
                cell != "box_id" &&
                cell != "product_id" &&
                (!cell || !parsedrow[cell] || isNaN(parsedrow[cell]))
              ) {
                row[cell] = {
                  value: 0,
                  err: "",
                };
              } else
                row[cell] = {
                  value: parsedrow[cell],
                  err: "",
                };
            }
            rowData["data"] = row;
            return rowData;
          }
        });
        setData(tempData);
      } else {
        setErr("cant read file.");
      }

      setLoading(false);
    };
    reader.readAsText(file);
  }



  const createFunction = async () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("product_id", product_id);

    if (file != null) {

      setLoading(true)
      dispatch(
        callApiAction(
          async () => await importProductStockCsvApi(formData, { product_id: product_id }),
          async (response) => {
            console.log("responses",response)
            // setFileErr(response.message)
            // console.log("Errorrorr",err)
            setLoading(false)
            dispatch(callSnackBar("CSV Imported Successfully", SNACK_BAR_VARIETNS.suceess))
            dispatch(closeModal())
          },
          (err) => {
            setFileErr(err)
            console.log("ERrrorrs",fileErr)
            dispatch(callSnackBar(fileErr, SNACK_BAR_VARIETNS.error));
            setLoading(false)
          }
        )
      )
    }
   
  }

  console.log("ERR",err)

  const onSubmit = async (e) => {
    e.preventDefault()
    createFunction()

  }
  return <ImportCsvCreateUi loading={loading} onFileSelect={onFileSelect} data={data} hasError={hasErr} onSubmit={onSubmit} fields={fields} product_id={product_id} fileErr={fileErr} setFileErr={setFileErr} />

}
export default ImportCsvCreateController