import React, { memo } from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import lkglass from "../assets/images/lkglass.svg";
import { Box } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import CustomerListController from "../pages/customer/CustomerViews/CustomerListController";
import DemoUnitListController from "../pages/demoUnits/DemoUnitListController";
import Franchise from "../pages/demoUnits/Franchise";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "1px solid #CECCFF",
  borderRadius: "5px",
  padding: "2px",
  marginBottom: theme.spacing(1),
  marginTop: "5px",
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <KeyboardArrowDown sx={{ fontSize: "40px", color: "#1B4381" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "white",
  padding: theme.spacing(0),
  flexDirection: "row",
  alignItems: "center",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(2),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  display: "flex",
  flex: 1,
  flexDirection: "row",
  justifyContent: "space-evenly",
  backgroundColor: "white",
  borderTop: "1px solid #CECCFF ",
}));

const ReusableAccordion = memo(({ Component, handleChange, expanded, item, module, franchise, id }) => {
  return (
    <Accordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary>
        <Box>
          <Typography
            variant="h5"
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            {/* <img src={lkglass} /> */}
            {item.name}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: "gray", marginLeft: "auto" }}
          >
            {item?.address}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {expanded && <Box component={Component} isAccordion={true} userId={item?._id} id={id} />}
      </AccordionDetails>
      
    </Accordion>
  );
});

export default function CustomizedAccordions({ item, module, franchise, Component, components }) {
  console.log("object module", module);
  const [expanded, setExpanded] = React.useState(null);

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded ? true : false);
  };
  return (
    <div>
      <ReusableAccordion
        item={item}
        id={item?._id}
        handleChange={handleChange}
        expanded={expanded}
        module={module}
        franchise={franchise}
        Component={Component}
      />
    </div>
  );
}
