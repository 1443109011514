import React, { useState } from 'react'
import CustomDialog from '../../components/layouts/common/CustomDialog'
import { CenteredBox } from '../../components/layouts/OneViewBox'
import { Box, Checkbox, CircularProgress, DialogContent, FormControlLabel, Grid, Typography } from '@mui/material'

const RepairUpdateUi = ({ list, loading, Title, checkedBoxes, setCheckedBoxes, handleCheckboxChange, onSubmit }) => {

    return (
        <CustomDialog
            id={"addstatus"}
            loading={loading}
            onSubmit={(e) => onSubmit(e)}
            title={Title}
            err={checkedBoxes?.err}
            closeText="Close"
            confirmText={Title}
        >
            {loading ? (
                <CenteredBox>
                    <CircularProgress />
                </CenteredBox>
            ) : (
                <>
                    {loading ? (
                        <CenteredBox>
                            <CircularProgress />
                        </CenteredBox>
                    ) :
                        <>
                            {/* <DialogContent
                                sx={{ minHeight: "100px", display: "flex", position: "relative" }}
                            > */}
                                <Box sx={{ minHeight: "100px", width: "100%", display: "flex" }}>
                                    <Box
                                        sx={{
                                            position: "relative",
                                            overflowY: "auto",
                                            display: "flex",
                                            flexDirection: "column",
                                            flex: 1.3,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                border: 1,
                                                p: 2,
                                                borderRadius: 1,
                                                position: "sticky",
                                                top: 0,
                                                background: "light",
                                            }}
                                        >
                                          
                                     

                                        <Box
                                            sx={{
                                                position: "relative",
                                                overflowY: "auto",
                                                height: "50vh",
                                            }}
                                        >
                                            {list?.length > 0 ? (
                                                list.map((item) => (
                                                    <FormControlLabel
                                                        key={item.box_id}
                                                        control={
                                                            <Checkbox
                                                                checked={checkedBoxes.boxes.includes(item.box_id)}
                                                                onChange={(e) =>
                                                                    handleCheckboxChange(item.box_id, e.target.checked)
                                                                }
                                                            />
                                                        }
                                                        label={item.box_id}
                                                    />
                                                ))
                                            ) : (<Box sx={{display:"flex",height:"100%",width:"100%",justifyContent:"center",alignItems:"center"}}>

                                                <Typography>No items available</Typography></Box>
                                            )}

                                        </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            {/* </DialogContent> */}

                        </>
                    }
                </>
            )}
        </CustomDialog >
    )
}

export default RepairUpdateUi