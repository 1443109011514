import React, { useEffect, useMemo, useState } from 'react'
import RepairUpdateUi from './RepairUpdateUi'
import { useDispatch } from 'react-redux';
import useValidate from '../../store/hooks/useValidator';
import { SEND_STATUS } from '../../utils/constants';

const RepairUpdateController = ({ buttonStatus, Title, sendStatus }) => {
    const [loading, setLoading] = useState();
    const [list, setList] = useState();
    const dispatch = useDispatch();
    const [checkedBoxes, setCheckedBoxes] = useState({
        boxes: [],
        err: "",
        repairing_status: sendStatus === SEND_STATUS.PENDING ? buttonStatus : buttonStatus + 1,
        send_status: sendStatus === SEND_STATUS.PENDING ? SEND_STATUS.ARRIVED : SEND_STATUS.PENDING
    });
    console.log("first checkedBoxes", checkedBoxes)
    const validate = useValidate();
    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        sort: "",
        sortDirection: -1,
        searchable: ""
    });

    const validationSchema = useMemo(() => ([
        {
            required: true,
            value: checkedBoxes.boxes[0],
            field: 'Please Select Box Id'
        },
    ]), [checkedBoxes])


    const onSubmit = async (e) => {
        e.preventDefault();
        const validationResponse = validate(validationSchema);

        if (validationResponse === true) {
            setLoading(true);
            dispatch(

            );
        } else {
            setCheckedBoxes({ ...checkedBoxes, err: validationResponse });
        }
    };


    const fetchList = () => {
        setLoading(true);
        dispatch(

        );
    };

    useEffect(() => {
        fetchList();
    }, [filters, buttonStatus]);


    const handleCheckboxChange = (boxId, isChecked) => {
        setCheckedBoxes((prev) => ({
            ...prev,
            boxes: isChecked
                ? [...prev.boxes, boxId]
                : prev.boxes.filter((id) => id !== boxId)
        }));
    };

    return (
        <RepairUpdateUi
            list={list}
            loading={loading}
            setCheckedBoxes={setCheckedBoxes}
            checkedBoxes={checkedBoxes}
            handleCheckboxChange={handleCheckboxChange}
            onSubmit={onSubmit}
            Title={Title}
        />
    )
}

export default RepairUpdateController