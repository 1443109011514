import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "./userReducer";
import snackBarReducer from "./snackbarReducer";
import modalReducer from "./modalReducer";
import notificationReducer from "./notificationReducer";
import settingsReducer from "./settingsReducer";
import QueryReducer from "./queryReducer";

const rootReducer = combineReducers({
    user: userReducer,
    snackBar: snackBarReducer,
    modal: modalReducer,
    notifications: notificationReducer,
    settings : settingsReducer,
    Query:QueryReducer
});
export default rootReducer;