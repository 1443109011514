import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { updateCustomerApi } from "../../../../apis/customer.api";
import useValidate from "../../../../store/hooks/useValidator";
import { callApiAction } from "../../../../store/actions/commonAction";
import { callSnackBar } from "../../../../store/actions/snackbarAction";
import { ORDER_STATUS, PAYMENT_TYPE, SNACK_BAR_VARIETNS } from "../../../../utils/constants";
import { closeModal } from "../../../../store/actions/modalAction";
import PurchasedProductUi from "./PurchasedProductUi";

const PurchasedProductController = ({ customer_type, id,callBack}) => {
  const dispatch = useDispatch();
  const validate = useValidate();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({
    id: id,
    customer_type: customer_type,
    payment_type:PAYMENT_TYPE.UPI,
     sold_details: {
          box_id: "",
          date: moment().toISOString(),
          amount: "",
          product:"",
          order_status:ORDER_STATUS.PENDING
        },
  });
  const [step, setStep] = useState(0);

  const validationSchemaTrial = React.useMemo(
    () => [
      {
        required: true,
        value: fields?.sold_details?.product,
        field: "product",
        message: "Product",
      },
      {
        required: true,
        value: fields?.sold_details?.box_id,
        field: "box_id",
        message: "Box ID",
      },
      {
        required: true,
        value: fields?.sold_details?.date,
        field: "date",
        message: "Date",
      },
      {
        required: true,
        value: fields?.sold_details?.amount,
        field: "amount",
        message: "Amount",
      },
    ],
    [fields]
  );

  const updateFunction = async (e) => {
    e.preventDefault();
    const validationResponse = validate(validationSchemaTrial);

    if (validationResponse === true) {
      setLoading(true);
      dispatch(
        callApiAction(
          async () => await updateCustomerApi({...fields}),
          async (response) => {
            setLoading(false);
            dispatch(closeModal("purchased-product"));
            callBack()
            dispatch(
              callSnackBar("Payment Done Successfully", SNACK_BAR_VARIETNS.suceess)
            );
          },
          (err) => {
            setLoading(false);
            dispatch(callSnackBar("Error", SNACK_BAR_VARIETNS.error));
            setFields({ ...fields, err });
          }
        )
      );
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault()
    const validationSchemaArr = [
      validationSchemaTrial,
    ];
    if (step == 1) {
      updateFunction(e);
      return;
    }
    const validationResponse = validate(validationSchemaArr[step]);

    if (validationResponse === true) {
      setStep(step + 1);
    } else {
      setFields({ ...fields, err: validationResponse });
    }
  };

  const onPrevStep = async() =>{
    if (!loading) {
      if ( step == 0) {
          dispatch(closeModal("purchased-product"))
      }else if(step == 1){
        setStep((step - 1))
      }

  }
  }

  return (
    <>
      <PurchasedProductUi
      loading={loading}
        onSubmit={onSubmit}
        fields={fields}
        setFields={setFields}
        step={step}
        setStep={setStep}
        onPrevStep={onPrevStep}
      />
    </>
  );
};

export default PurchasedProductController;
