import React, { useEffect, useState } from "react";
import SubmitButton from "../../../../components/button/SubmitButton";
import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import CustomDialog from "../../../../components/layouts/common/CustomDialog";
// import CustomerTrialProductUi from "./CustomerTrialProductUi";
import { useDispatch } from "react-redux";
import {
  PAYMENT_STATUS,
  PAYMENT_TYPE,
  SNACK_BAR_VARIETNS,
} from "../../../../utils/constants";
import moment from "moment";
import { callApiAction } from "../../../../store/actions/commonAction";
import { callSnackBar } from "../../../../store/actions/snackbarAction";
import {
  addPaymentWithUPI,
  updatePaymentApi,
} from "../../../../apis/payment.api";
import CustomInput from "../../../../components/inputs/CustomInputs";
import { updateCustomerApi } from "../../../../apis/customer.api";
import Payment from "../PaymentDetails/Payment";

const TrialProductPaymentUi = ({ fields, setFields }) => {
  const [url, setUrl] = useState("");
  const dispatch = useDispatch();
  const [buttonstep, setButtonStep] = useState(0);

  const onUpiPayment = (e) => {
    e.preventDefault();
    setButtonStep(1);
    dispatch(
      callApiAction(
        async () =>
          await addPaymentWithUPI({
            ...fields,
            amount: fields.sold_details.amount,
            upiId: "kkkd@okaiccl.ok",
          }),
        async (response) => {
          setUrl(response);
          setFields({ ...fields, payment_type: PAYMENT_TYPE.UPI });
        },
        (err) => {
          //   setLoading(false);
          dispatch(callSnackBar("Error", SNACK_BAR_VARIETNS.error));
          setFields({ ...fields, err });
        }
      )
    );
  };

  const onCashPayment = (e) => {
    e.preventDefault();
    setButtonStep(0);
    setFields({ ...fields, payment_type: PAYMENT_TYPE.CASH });
    setUrl("");
  };

  return (
    <>
      <Box
        mb={2}
        sx={{
          display: "flex",
          gap: "5px",
        }}
      >
        <ButtonGroup
          sx={{ color: "#101536" }}
          fullWidth
          size="large"
          aria-label="Large button group"
        >
          <Button
            sx={buttonstep !== 0 && { backgroundColor: "white" }}
            variant={buttonstep == 0 ? "contained" : "outlined"}
            onClick={(e) => onCashPayment(e)}
            fullWidth
          >
            Cash
          </Button>

          <Button
            sx={buttonstep !== 1 && { backgroundColor: "white" }}
            variant={buttonstep == 1 ? "contained" : "outlined"}
            onClick={(e) => onUpiPayment(e)}
            fullWidth
          >
            Upi
          </Button>

          <Button
            sx={buttonstep !== 2 && { backgroundColor: "white" }}
            variant={buttonstep == 2 ? "contained" : "outlined"}
            onClick={() => setButtonStep(2)}
            fullWidth
          >
            Card
          </Button>
        </ButtonGroup>
      </Box>

      {url && buttonstep == 1 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography variant="h4">Scan to Pay:</Typography>
            <img
              src={url}
              alt="QR Code"
              style={{ width: "300px", marginTop: "10px" }}
            />
          </Box>
        </Box>
      )}
      {(buttonstep == 0 || buttonstep == 1) && (
        <CustomInput
          autoFocus={true}
          disabled={fields?.sold_details?.amount}
          value={fields?.sold_details?.amount}
          type="number"
          label={"Amount*"}
        />
      )}
      {buttonstep == 2 && (
        <Payment
          date={fields?.sold_details?.date}
          amount={fields?.sold_details?.amount}
        />
      )}
    </>
  );
};

export default TrialProductPaymentUi;
