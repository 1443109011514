import { Box, ListItem, Typography } from "@mui/material";
import AsyncDropDown from "../../../../components/inputs/AsyncDropDown";
import { fetchProductApi } from "../../../../apis/product.api";
import { StyledSearchBar } from "../../../../components/inputs/SearchBar";
import CustomInput from "../../../../components/inputs/CustomInputs";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";

const TrialProductDetailsUi = ({loading,fields,setFields}) =>{

return(<>

<Box mt={4}>
<Box height={"10px"}>
  <Typography variant="h5" color={"red"}>
    {fields?.err}
  </Typography>
</Box>
<Box
  gap={4}
  display={"flex"}
  flexDirection={"row"}
  alignItems={"center"}
  justifyContent={"center"}
>
<Box mt={2} width={"50%"}>
  { console.log("product name", fields?.sold_details?.product)}
                  <AsyncDropDown
                    defaultVal={fields?.sold_details?.product?._id && {
                      _id: fields?.sold_details?.product?._id,
                      product_name: fields?.sold_details?.product?.product_name,
                    }}
                    lazyFun={async (para) =>
                      await fetchProductApi({ ...para, allStatus: true })
                    }
                    OptionComponent={({ option, ...rest }) => {
                      console.log("optionss",option)
                      return (
                        <ListItem {...rest}>{option.product_name}</ListItem>
                      );
                    }}
                    // value={fields?.sold_details?.product?.product_name}
                    onChange={(changedVal) => {
                      console.log("this is changes value", changedVal);
                      setFields((prevFields) => ({
                        ...prevFields,
                        sold_details: {
                          ...prevFields?.sold_details,
                          product: changedVal?._id ? changedVal?._id : [],
                        },
                        err: "",
                      }));
                    }}
                    titleKey={"product_name"}
                    valueKey={"_id"}
                    InputComponent={(params) => (
                      <StyledSearchBar
                        placeholder={"Select Product*"}
                        {...params}
                        margin="none"
                      />
                    )}
                  />
                </Box>

                <Box width={"50%"}>
                  <CustomInput
                    value={fields?.sold_details?.box_id || ""}
                    onChange={(e) =>
                      setFields({
                        ...fields,
                        sold_details: {
                          ...fields.sold_details,
                          ...fetchProductApi.sold_details,
                          box_id: e.target.value,
                        },
                      })
                    }
                    type="text"
                    label="Enter Box Id*"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  gap: 2,
                }}
              >
                <Box width={"50%"}>
                  <CustomInput
                    disabled={loading}
                    value={fields?.sold_details?.amount}
                    onChange={(e) =>
                      setFields({
                        err: "",
                        ...fields,
                        sold_details: {
                          ...fields.sold_details,
                          amount: e.target.value,
                        },
                      })
                    }
                    type="text"
                    label={"Amount*"}
                  />
                </Box>

                <Box width={"50%"}>
                  <DesktopDatePicker
                    // sx={{ width: "18vw", height: "100%" }}
                    disableFuture
                    inputFormat="DD/MM/yyyy"
                    renderInput={(props) => <CustomInput {...props} />}
                    label={"Date*"}
                    disabled={loading}
                    value={fields?.sold_details?.date}
                    onChange={(e) => {
                      setFields({
                        ...fields,
                        sold_details: {
                          ...fields.sold_details,
                          date: moment(e).toISOString(),
                        },
                      });
                    }}
                  />
                </Box>
                </Box>
             
</Box>
</>)
}

export default TrialProductDetailsUi;