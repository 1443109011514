import { Autocomplete, Box, CircularProgress, ListItem } from "@mui/material";
import { memo } from "react";
import { useSelector } from "react-redux";
import { USER_ROLES } from "../../utils/constants";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import CustomInput from "../../components/inputs/CustomInputs";
import {
  findNameByRole,
  findObjectKeyByValue,
  titleCase,
} from "../../utils/main";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import { getUserApi } from "../../apis/user.api";
import { StyledSearchBar } from "../../components/inputs/SearchBar";

const CreateUserUi = ({
  title,
  isInstaller,
  isUpdate,
  fields,
  setFields,
  loading,
  onSubmit,
  userrole,
}) => {
  const { user } = useSelector((state) => state);
  console.log("User Role:", user.data.role);

  let allowedRoles = Object.keys(USER_ROLES)
    .map((key) => ({
      label: titleCase(key.replace(/_/g, " ")), // Convert keys to readable format
      _id: USER_ROLES[key],
    }))
    .filter((role) => user.data.role === 1 || role._id > user.data.role); // Include all roles for role = 1, otherwise filter based on role._id

  console.log("Allowed Roles:", allowedRoles);

  if (user.data.role === USER_ROLES.partner_admin) {
    allowedRoles = [];
    Object.keys(USER_ROLES).forEach((key) => {
      if (USER_ROLES[key] != USER_ROLES.admin)
        allowedRoles.push({ label: titleCase(key), _id: USER_ROLES[key] });
    });
  }

  return (
    <>
      <CustomDialog
        loading={loading}
        err={fields.err}
        onSubmit={onSubmit}
        title={`${isUpdate ? "Update" : "Create"} ${title}`}
        closeText="Close"
        confirmText={`${isUpdate ? "Update" : "Create"}`}
      >
        {loading ? (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        ) : (
          <>
            <CustomInput
              autoFocus={true}
              disabled={loading}
              value={fields.name}
              onChange={(e) =>
                setFields({ ...fields, err: "", name: e.target.value })
              }
              type="text"
              label={"Name*"}
            />

            {!isUpdate && (
              <CustomInput
                disabled={loading}
                value={fields.email}
                onChange={(e) =>
                  setFields({ ...fields, err: "", email: e.target.value })
                }
                type="text"
                label={"Email*"}
              />
            )}

            {!isUpdate && (
              <Box mt={2} mb={1}>
                <CustomInput
                  disabled={loading}
                  value={fields.password}
                  onChange={(e) =>
                    setFields({ ...fields, err: "", password: e.target.value })
                  }
                  type="password"
                  label={"Password*"}
                />
              </Box>
            )}
            {
              <CustomInput
                disabled={loading}
                value={fields.phone}
                onChange={(e) =>
                  setFields({ ...fields, err: "", phone: e.target.value })
                }
                type="tel"
                label={"Phone No*"}
              />
            }
            {!userrole && (
              <Box mt={1}>
                <Autocomplete
                  disableClearable
                  options={allowedRoles}
                  value={findNameByRole(fields.role)}
                  // id="user-role-id"
                  // name="user-role-id"
                  onChange={(e, newVal) => {
                    setFields({ ...fields, role: newVal ? newVal._id : null });
                  }}
                  sx={{
                    width: "100%",
                    display: "flex",
                    "*": { display: "flex", justifyContent: "center" },
                  }}
                  renderInput={(params) => (
                    <CustomInput
                      placeholder="Select Role*"
                      {...params}
                      label="Select Role*"
                      margin="dense"
                    />
                  )}
                />
              </Box>
            )}

            {(fields.role === USER_ROLES.SALES_CHANNEL_PARTNER ||
              fields.role === USER_ROLES.FRANCHISE_PARTNER ||
              fields.role === USER_ROLES.SALES_STAFF) && (
              <Box mt={1}>
                <AsyncDropDown
                  defaultVal={
                    fields?.parent_id?.length > 0
                      ? {
                          _id: fields?.parent_id[fields?.parent_id?.length - 1]
                            ?._id,
                          name: fields?.parent_id[fields?.parent_id?.length - 1]
                            ?.name,
                        }
                      : null
                  }
                  lazyFun={async (para) =>
                    await getUserApi({ ...para, isDropDown: true })
                  }
                  OptionComponent={({ option, ...rest }) => {
                    return (
                      <ListItem {...rest}>
                        {option.name} (
                        {findObjectKeyByValue(option.role, USER_ROLES)})
                      </ListItem>
                    );
                  }}
                  value={fields?.userId}
                  onChange={async (changedVal) => {
                    setFields({
                      ...fields,
                      userId: changedVal ? changedVal._id : null,
                    });
                  }}
                  titleKey={"name"}
                  valueKey={"_id"}
                  InputComponent={(params) => (
                    <StyledSearchBar
                      placeholder={"Assign To*"}
                      {...params}
                      margin="none"
                    />
                  )}
                />
              </Box>
            )}

            {(fields.role === USER_ROLES.SALES_CHANNEL_PARTNER ||
              fields.role === USER_ROLES.FRANCHISE_PARTNER ||
              fields.role === USER_ROLES.SALES_STAFF) && (
              <CustomInput
                disabled={loading}
                value={fields.address}
                onChange={(e) =>
                  setFields({ ...fields, err: "", address: e.target.value })
                }
                type="address"
                label={"Address*"}
              />
            )}
            <Box display="flex" gap={2} mt={0.5}>
              {(fields.role === USER_ROLES.SALES_CHANNEL_PARTNER ||
                fields.role === USER_ROLES.FRANCHISE_PARTNER ||
                fields.role === USER_ROLES.SALES_STAFF ||
                fields.role === USER_ROLES.FRANCHISE_STAFF) && (
                <CustomInput
                  disabled={loading}
                  value={fields.insentive}
                  onChange={(e) => {
                    const inputValue = parseFloat(e.target.value);
                    if (inputValue >= 0 && inputValue <= 99) {
                      setFields({
                        ...fields,
                        err: "",
                        insentive: e.target.value,
                      });
                    } else if (inputValue < 0) {
                      setFields({
                        ...fields,
                        err: "Percentage cannot be negative",
                      });
                    } else {
                      setFields({
                        ...fields,
                        err: "Percentage should be less than 100",
                      });
                    }
                  }}
                  type="number"
                  label={"Incentive (in %)*"}
                  onWheel={(e) => e.target.blur()}
                  sx={{
                    "& input::-webkit-inner-spin-button, & input::-webkit-outer-spin-button":
                      {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                    "& input": {
                      MozAppearance: "textfield",
                    },
                  }}
                />
              )}
              {(fields.role === USER_ROLES.SALES_CHANNEL_PARTNER ||
                fields.role === USER_ROLES.FRANCHISE_PARTNER ||
                fields.role === USER_ROLES.SALES_STAFF ||
                fields.role === USER_ROLES.FRANCHISE_STAFF) && (
                <CustomInput
                  disabled={loading}
                  value={fields.zip_code}
                  onChange={(e) =>
                    setFields({ ...fields, err: "", zip_code: e.target.value })
                  }
                  type="text"
                  label={"Zip code*"}
                />
              )}
            </Box>
            <Box display="flex" gap={2} mt={0.5}>
              {(fields.role === USER_ROLES.SALES_CHANNEL_PARTNER ||
                fields.role === USER_ROLES.FRANCHISE_PARTNER ||
                fields.role === USER_ROLES.SALES_STAFF ||
                fields.role === USER_ROLES.FRANCHISE_STAFF) && (
                <CustomInput
                  disabled={loading}
                  value={fields.state}
                  onChange={(e) =>
                    setFields({ ...fields, err: "", state: e.target.value })
                  }
                  type="text"
                  label={"State*"}
                />
              )}
              {(fields.role === USER_ROLES.SALES_CHANNEL_PARTNER ||
                fields.role === USER_ROLES.FRANCHISE_PARTNER ||
                fields.role === USER_ROLES.SALES_STAFF ||
                fields.role === USER_ROLES.FRANCHISE_STAFF) && (
                <CustomInput
                  disabled={loading}
                  value={fields.city}
                  onChange={(e) =>
                    setFields({ ...fields, err: "", city: e.target.value })
                  }
                  type="text"
                  label={"City*"}
                />
              )}
            </Box>
          </>
        )}
      </CustomDialog>
    </>
  );
};
export default memo(CreateUserUi);
