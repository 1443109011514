import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import QRCode from "qrcode";
import { getPaymentURL } from "../../../../apis/payment.api";
import loadingImg from "../../../../assets/images/loading.gif";

import { io } from "socket.io-client";

const Payment = ({ amount, date }) => {
  const [qrCode, setQrCode] = useState(""); // State to store QR code image URL
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({});

  const socket = useMemo(
    () => io("https://wehear-backendv2-dms-20-development.up.railway.app/"),
    []
  );

  const generateQRurl = async () => {
    setLoading(true);
    try {
      const response = await getPaymentURL({
        amount: amount || 1,
      });
      setUrl(response?.data?.short_url);
      setResponse(response?.data); // Save the response to state for later use (order_id)
      setLoading(false);
    } catch (error) {
      console.error("Error generating payment URL:", error);
      setLoading(false);
    }
  };

  const generateQRCode = async () => {
    try {
      const qrCodeURL = await QRCode.toDataURL(url, {
        width: 300,
        margin: 2,
      });
      setQrCode(qrCodeURL); // Save the generated QR code URL to state
    } catch (err) {
      console.error("Error generating QR code:", err);
    }
  };

  useEffect(() => {
    // Generate the payment URL and then the QR code
    generateQRurl();
  }, []);

  useEffect(() => {
    if (url) {
      generateQRCode();
    }
  }, [url]);

  useEffect(() => {
    // Subscribe to WebSocket for payment success once we have the response (order_id)
    if (response?.id) {
      socket.emit("subscribe", response?.id);
    }

    // Clean up WebSocket connection when the component is unmounted
    return () => {
      socket.off("payment-success"); // Remove listeners to prevent memory leaks
    };
  }, [response]);

  // Handle payment success event from WebSocket
  // useEffect(() => {
  //   socket.on("payment-success", (data) => {
  //     if (data.success) {
  //       console.log("this is payment-success")
  //       // Redirect to the success page when payment is successful
  //       window.location.href = "/success-page"; // Your success page URL
  //     }
  //   });

  //   return () => {
  //     socket.off("payment-success");
  //   };
  // }, []);

  socket.on("payment-success", () => {
    window.location.href = "/customer"; // Your success page URL
  });

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "60vh",
            justifyContent: "center",
          }}
        >
          <img
            src={loadingImg}
            alt="Loading..."
            style={{ width: "60px", marginTop: "10px" }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            padding: "16px",
            minWidth: "60%",
          }}
        >
          {" "}
          <Typography variant="h4">
            Scan the QR code to proceed with payment using a card:
          </Typography>
          <Grid container alignItems="center">
            {/* Left Section: Basic Details */}
            <Grid item xs={5}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  width: "100%",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  gap: "8px",
                  padding: "16px",
                }}
              >
                <Typography variant="body1">
                  <strong>Amount :</strong> {amount}
                </Typography>
                <Typography variant="body1">
                  <strong>Date :</strong>{" "}
                  {new Date(date).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </Typography>
              </Box>
            </Grid>

            {/* Middle Section: Vertical Line */}
            <Grid item xs={1}>
              <Box
                sx={{
                  height: "100%",
                  borderLeft: "2px solid #CCCCCC",
                }}
              ></Box>
            </Grid>

            {/* Right Section: QR Code */}
            <Grid item xs={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                {qrCode ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img
                      src={qrCode}
                      alt="QR Code"
                      style={{ width: "300px", marginTop: "10px" }}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography variant="h6">Something went wrong!</Typography>
                    <button onClick={generateQRurl}>Retry</button>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default Payment;
