import React, { useEffect } from 'react'
import DataTable from '../../components/tables/DataTable'
import { Box, Button, ButtonGroup, Grid2, Paper, Skeleton, Typography, useMediaQuery } from '@mui/material'
import { FilterTitleBox } from '../../components/layouts/OneViewBox'
import PaddingBoxInDesktop from '../../components/layouts/PaddingBoxDesktop'
import AsyncSearchBar from '../../components/inputs/AsyncSearchBar'
import { useDispatch, useSelector } from 'react-redux'
import { openModal } from '../../store/actions/modalAction'
// import CreateQueryController from './CreateQueryController'
import AddIcon from "@mui/icons-material/Add";
import ButtonComponentsUi from '../../components/button/ButtonComponentsUi'
import { QUERY_STATUS, USER_ROLES } from '../../utils/constants'
import { findObjectKeyByValue, titleCase } from '../../utils/main'
import ReplacementModal from './ReplacementModal'

const ReplacementUi = ({ setButtonStatus,
    selectedButtonId,
    setSelectedButtonId,
    buttonStatus,
    columns,
    list,
    filters,
    setFilters,
    itemType,
    loading,
    count,
    countLoading,
    current,
    setCurrent
}) => {
    const user = useSelector((state) => state.user);
    const { settings } = useSelector((state) => state);
    const dispatch = useDispatch();

    const onChannel = (arrived) => {
        dispatch(openModal(<ReplacementModal arrived={arrived} current={current} />, "md", false));
    };

    return (
        <Box sx={{ scrollbarWidth: "0px" }}>
            <Paper sx={{ padding: 4, width: "100%" }}>
                <Box m={3}>
                    <Typography variant="h3">Replacement</Typography>
                </Box>
                {/* <Box pl={2}>
                    <FilterTitleBox>
                        {user.data.role == USER_ROLES.LENSKART_STORE && (
                            <Box width={"48%"}>
                                <ButtonGroup sx={{ flexDirection: "row", width: "100%" }}>
                                    <ButtonComponentsUi
                                        STATUSWiseData={current}
                                        onSubmit={() => {
                                            setCurrent(true);
                                        }}
                                        ButtonGroup
                                        Title={"Returned Stock"}
                                    />
                                    <ButtonComponentsUi
                                        onSubmit={() => {
                                            setCurrent(false);
                                        }}
                                        STATUSWiseData={!current}
                                        ButtonGroup
                                        Title={"Pending to wehear"}
                                    />
                                </ButtonGroup>
                            </Box>
                        )}
                        {user.data.role == USER_ROLES.WEHEAR_ADMIN && (
                            <Box width={"48%"}>
                                <ButtonGroup sx={{ flexDirection: "row", width: "100%" }}>
                                    <ButtonComponentsUi
                                        STATUSWiseData={current}
                                        onSubmit={() => {
                                            setCurrent(true);
                                        }}
                                        ButtonGroup
                                        Title={"Arrived"}
                                    />
                                    <ButtonComponentsUi
                                        onSubmit={() => {
                                            setCurrent(false);
                                        }}
                                        STATUSWiseData={!current}
                                        ButtonGroup
                                        Title={"Pending"}
                                    />
                                </ButtonGroup>
                            </Box>
                        )}
                        <Box>
                            <PaddingBoxInDesktop
                                sx={{
                                    display: "flex",
                                    flexDirection: "flex-end",
                                }}
                            >
                                {user.data.role == USER_ROLES.LENSKART_STORE && (
                                    <Button onClick={() => { onChannel(false) }} variant="contained">
                                        <Typography>Return to wehear</Typography>
                                    </Button>
                                )}
                                {user.data.role == USER_ROLES.WEHEAR_ADMIN && !current && (
                                    <Button onClick={() => { onChannel(true) }} variant="contained">
                                        <Typography>Arrived</Typography>
                                    </Button>
                                )}
                            </PaddingBoxInDesktop>
                        </Box>
                    </FilterTitleBox>
                </Box> */}
                {countLoading ? (
                    <Box
                        mt={4}
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                            height: "20vh",
                        }}
                    >
                        {[0, 1, 2, 3].map((row) => (
                            <Skeleton
                                key={row}
                                variant="rectangular"
                                width={"24%"}
                                height={120}
                                sx={{
                                    borderRadius: "8px",
                                    boxShadow:
                                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                                }}
                            />
                        ))}
                    </Box>
                ) : (
                    <Grid2
                        container
                        mt={4}
                        p={2}
                        size={12}
                        justifyContent={"space-between"}
                    >
                        {" "}
                        {count?.map((listData) => (
                            <>
                                <Grid2
                                    display={"flex"}
                                    mb={4}
                                    width={"18vw"}
                                    flexDirection={"row"}
                                    justifyContent={"flex-start"}
                                    flexWrap={"wrap"}
                                    size={listData.length / 3}
                                >
                                    <ButtonComponentsUi
                                        count={listData?.count ?? 0}
                                        onSubmit={() => {
                                            setSelectedButtonId(listData._id);
                                        }}
                                        colorType={selectedButtonId === listData._id}
                                        Title={listData?.product_name}
                                    />
                                </Grid2>
                            </>
                        ))}
                    </Grid2>
                )}
                <Box p={2} sx={{ minHeight: "40vh" }}>
                    <DataTable
                        columns={columns}
                        rows={list?.result ? list.result : []}
                        count={list?.result?.length ?? 0}
                        filters={filters}
                        setFilters={setFilters}
                        loading={loading}
                    />
                </Box>
            </Paper>
        </Box>
    );

}

export default ReplacementUi