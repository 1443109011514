import axios from "axios";
import endpoints from "./endpoints";
import { getHeaders } from "../utils/main";


export const FetchIncentiveApi = async (params) => {
    const callResponse = await axios({
        url: endpoints.fetchIncentive,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then((response) => response.data)
        .catch((err) => err.response.data);

    return callResponse;
};

export const CreateIncentiveApi = async (data) => {
    const callResponse = await axios({
        url: endpoints.CreateIncentive,
        method: "post",
        headers: getHeaders(),
        data,
    })
        .then((response) => response.data)
        .catch((err) => ({ status: 0, response: err.response, code: err.response.status }));

    return callResponse;
};