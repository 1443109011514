import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ReturnDataListUi from "./ReturnDataListUi";
import { findObjectKeyByValue } from "../../utils/helper";
import { callApiAction } from "../../store/actions/commonAction";
import {
  INVENTORY_TYPE,
  QUERY_STATUS,
  RECEIVED_STATUS_NAME,
  SEND_STATUS,
  SEND_STATUS_NAME,
  SNACK_BAR_VARIETNS,
  USER_ROLES,
} from "../../utils/constants";
import { Box, Button, Chip, Typography } from "@mui/material";
import { StatusColor, titleCase } from "../../utils/main";
import { openModal } from "../../store/actions/modalAction";
import { returnInventory } from "../../apis/inventory.api";
import { callSnackBar } from "../../store/actions/snackbarAction";
import { closeModal } from "../../store/actions/modalAction";
import MessageDilog from "../../components/MessageDilog";
import CustomerInformationController from "../customer/CustomerViews/CustomerInformationController";

const ActionComponent = ({
  params,
  setParams,
  deleteApi,
  deleted,
  callback,
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state);

  const onSubmit = (e, arrived) => {
    e.preventDefault();
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await returnInventory({ boxes: [params.box_id], arrived }),
        (response) => {
          setLoading(false);
          dispatch(
            callSnackBar(
              arrived ? "Received" : "Send to wehear",
              SNACK_BAR_VARIETNS.suceess
            )
          );
          dispatch(closeModal());
          callback();
        },
        (err) => {
          setLoading(false);
          dispatch(closeModal());
        }
      )
    );
  };

  const onSend = (e) => {
    dispatch(
      openModal(
        <MessageDilog
          onSubmit={(e) => {
            onSubmit(e, false);
          }}
          loading={loading}
          title="Alert!"
          message={`Are you sure you want to Send device to weHear "${params.box_id}" ?`}
        />,
        "sm"
      )
    );
  };

  const onReceived = (e) => {
    dispatch(
      openModal(
        <MessageDilog
          onSubmit={(e) => {
            onSubmit(e, true);
          }}
          loading={loading}
          title="Alert!"
          message={` Have you received the device "${params.box_id}" ?`}
        />,
        "sm"
      )
    );
  };

  return (
    <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
      {user.data.role == USER_ROLES.LENSKART_STORE &&
        params?.status == SEND_STATUS.PENDING_TO_SEND && (
          <Button variant="contained" color="primary" onClick={onSend}>
            <Typography variant="body2"> SEND TO WEHEAR</Typography>
          </Button>
        )}

      {user.data.role == USER_ROLES.WEHEAR_ADMIN &&
        params?.status == SEND_STATUS.PENDING && (
          <Button variant="contained" color="primary" onClick={onReceived}>
            <Typography variant="body2">Received</Typography>
          </Button>
        )}
    </Box>
  );
};

const ReturnDataListController = () => {
  const [buttonStatus, setButtonStatus] = useState(INVENTORY_TYPE.ALL);
  const [loading, setLoading] = useState(false);
  const [countLoading, setCountLoading] = useState(false);
  const dispatch = useDispatch();
  const [count, setCount] = useState([]);
  const fetchApi = "";
  const [list, setList] = useState({});
  const { settings } = useSelector((state) => state);
  const [selectedButtonId, setSelectedButtonId] = useState(null);
  const [current, setCurrent] = useState(true);
  const { user } = useSelector((state) => state);

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    sort: "",
    sortDirection: -1,
    searchable: ["product_name,box_id,product_code"],
    startDate: moment().startOf("day").valueOf(),
    endDate: moment().endOf("day").valueOf(),
  });

  const onVeiw = (id) => {
    dispatch(
      openModal(
        <CustomerInformationController id={id} />,
        "xl",
        false,
        "CustomerInformation"
      )
    );
  };

  const fetchCount = () => {
    setCountLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ current }),
        (response) => {
          setCount(response);
          setCountLoading(false);
        },
        (err) => {
          setCountLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchCount();
  }, [filters, buttonStatus, current]);

  const columns = useMemo(() => {
    return [
      {
        id: 1,
        minWidth: "50px",
        fieldName: "box_id",
        label: "Box Id",
        align: "left",
        sort: true,
        renderValue: (params) => params.box_id,
      },
      {
        id: 5,
        minWidth: "50px",
        fieldName: "customer_name",
        label: "Customer",
        align: "left",
        renderValue: (params) => (
          <Typography
            onClick={() => {
              onVeiw(params?.customer_id?._id);
            }}
            sx={{ cursor: "pointer" }}
          >
            {titleCase(
              params?.customer_id?.first_name +
              " " +
              params?.customer_id?.last_name
            )}
          </Typography>
        ),
      },
      {
        id: 2,
        minWidth: "50px",
        fieldName: "product_name",
        label: "Product",
        align: "left",
        renderValue: (params) => params?.product_id?.product_name,
      },
      {
        id: 4,
        fieldName: "type",
        label: "Type",
        align: "left",
        renderValue: (params) =>
          findObjectKeyByValue(params?.type, QUERY_STATUS),
      },
      {
        id: 3,
        fieldName: "status",
        label: "Status",
        align: "left",
        renderValue: (params) => {
          return (
            <Chip
              size="small"
              key={params?.status}
              color={StatusColor(params?.status)}
              label={
                user.data.role == USER_ROLES.LENSKART_STORE
                  ? titleCase(
                    findObjectKeyByValue(params?.status, SEND_STATUS_NAME)
                  )
                  : titleCase(
                    findObjectKeyByValue(params?.status, RECEIVED_STATUS_NAME)
                  )
              }
            />
          );
        },
      },
      {
        id: 6,
        fieldName: "action",
        label: "Action",
        align: "left",
        renderValue: (params) => {
          return (
            <ActionComponent
              callback={() => {
                fetchCount();
              }}
              params={params}
            />
          );
        },
      },
    ];
  }, [dispatch, buttonStatus]);

  const fetchList = () => {
    
  };

  useEffect(() => {
    if (count.length > 0) {
      if (!selectedButtonId) {
        setSelectedButtonId(count[0]._id);
      } else {
        fetchList();
      }
    }
  }, [count]);

  useEffect(() => {
    fetchList();
  }, [filters, buttonStatus, selectedButtonId, current]);

  return (
    <ReturnDataListUi
      setButtonStatus={setButtonStatus}
      buttonStatus={buttonStatus}
      loading={loading}
      filters={filters}
      setFilters={setFilters}
      current={current}
      setCurrent={setCurrent}
      count={count}
      columns={columns}
      list={list}
      setSelectedButtonId={setSelectedButtonId}
      selectedButtonId={selectedButtonId}
      countLoading={countLoading}
      callback={() => {
        fetchCount();
      }}
    />
  );
};

export default ReturnDataListController;
