import axios from "axios";
import endpoints from "./endpoints";
import { getHeaders } from "../utils/main";



export const getSalesCountApi = async (params) => {
    const callResponse = await axios({
        url: endpoints.salesCountApi,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then((response) => response.data)
        .catch((err) => err.response.data);

    return callResponse;
};

export const getSalesDataApi = async (params) => {
    const callResponse = await axios({
        url: endpoints.fetchSalesData,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then((response) => response.data)
        .catch((err) => err.response.data);

    return callResponse;
};


export const getSalesValueApi = async (params) => {
    const callResponse = await axios({
        url: endpoints.fetchSalesValue,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then((response) => response.data)
        .catch((err) => err.response.data);

    return callResponse;
};


export const updateSalesStatus = async (data) => {
    const callResponse = await axios({
        url: endpoints.updateSalesStatus,
        method: "PATCH",
        headers: getHeaders(),
        data,
    })
        .then((response) => response.data)
        .catch((err) => err.response.data);

    return callResponse;
};