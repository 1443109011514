import { Box } from "@mui/material";
import CountListController from "./CountWiseAnalysis/CountListController";
import ProductListController from "./ProductWiseAnalysis/ProductListController";
import ScpListController from "./ScpWiseAnalysis/ScpListController";
import { USER_ROLES } from "../../utils/constants";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const DashboardMain = () => {
    const { user } = useSelector((state) => state)
    return (
        <Box width={"100%"} p={2} sx={{ backgroundColor: "white" }} height={"100%"}>
            <CountListController />
            <ProductListController />
            {user?.data?.role === USER_ROLES.WEHEAR_ADMIN && <ScpListController role={USER_ROLES.SALES_CHANNEL_PARTNER} />}
            {((user?.data?.role === USER_ROLES.WEHEAR_ADMIN) || (user?.data?.role === USER_ROLES.SALES_CHANNEL_PARTNER)) && <ScpListController role={USER_ROLES.FRANCHISE_PARTNER} />}
        </Box>
    );
};
export default DashboardMain;