import React, { useEffect } from 'react'
import DataTable from '../../components/tables/DataTable'
import { Box, Button, ButtonGroup, Grid2, Paper, Skeleton, Typography, useMediaQuery } from '@mui/material'
import { FilterTitleBox } from '../../components/layouts/OneViewBox'
import PaddingBoxInDesktop from '../../components/layouts/PaddingBoxDesktop'
import AsyncSearchBar from '../../components/inputs/AsyncSearchBar'
import { useDispatch, useSelector } from 'react-redux'
import { openModal } from '../../store/actions/modalAction'
// import CreateQueryController from './CreateQueryController'
import AddIcon from "@mui/icons-material/Add";
import ButtonComponentsUi from '../../components/button/ButtonComponentsUi'
import { QUERY_STATUS, REPAIRING_STATUS, SEND_STATUS, USER_ROLES } from '../../utils/constants'
import { findObjectKeyByValue, titleCase } from '../../utils/main'
import TimeRangeSelector from '../../components/layouts/common/TimeRangeSelector'
import RepairUpdateController from './RepairUpdateController'

const RepairUi = ({ filters, countLoading, sendStatus, setSendStatus, count, setFilters, setButtonStatus, buttonStatus, loading, list, columns, setSelectedButtonId, selectedButtonId }) => {
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"))
    const id = "addstatus";
    const AddButtonName = (activeStep) => {

        switch (activeStep) {
            case 1:
                return "Send To Wehear";
            case 2:
                return sendStatus === SEND_STATUS.PENDING ? "Arrived" : "Start Repair"
            case 3:
                return "Repair Complete";
            case 4:
                return "Send To Store";
            case 5:
                return sendStatus === SEND_STATUS.PENDING ? "Arrivede" : "Send To Customer";
            // case 6:
            //     return sendStatus === SEND_STATUS.PENDING ? "Arrivede" : "Send To Customer";
            default:
                return;
        }
    }

    
    const onCreateBtnClick = () => {
        dispatch(openModal(<RepairUpdateController buttonStatus={buttonStatus} sendStatus={sendStatus} Title={AddButtonName(buttonStatus)} />, "sm", false, id));
    };

    // const CountButtonGroup = buttonStatus == REPAIRING_STATUS.SEND_TO_WEHEAR ? (user.data.role === USER_ROLES.WEHEAR_ADMIN ? (count[4]?.count ?? 0) - (list?.total ?? 0) ?? 0 : (count[4]?.count ?? 0)) : (user.data.role === USER_ROLES.WEHEAR_ADMIN ? (count[4]?.count ?? 0) : (count[3]?.count ?? 0))
    return (
        <>
            <Box>
                <Paper
                    sx={{
                        width: "100%",
                        padding: 6,
                    }}
                >
                    <Box mb={4} display="flex" flexDirection="column" gap={isSmallScreen ? 2 : 4}>
                        <FilterTitleBox>
                            <Typography
                                variant={isSmallScreen ? "h2" : "h3"}
                                textAlign={isSmallScreen ? "left" : "left"}
                                marginBottom={isSmallScreen ? '5px' : '0'}
                                color={"#000"}>
                                Repair List
                            </Typography>
                            <Box sx={{ height: "6vh" }}>
                                {((buttonStatus === REPAIRING_STATUS.QUERY_RAISE)
                                    // || (user.data.role === USER_ROLES.LENSKART_STORE && buttonStatus === REPAIRING_STATUS.SEND_TO_STORE)
                                    // || (user.data.role === USER_ROLES.WEHEAR_ADMIN && buttonStatus === REPAIRING_STATUS.SEND_TO_WEHEAR)
                                    || (user.data.role === USER_ROLES.WEHEAR_ADMIN && buttonStatus === REPAIRING_STATUS.SEND_TO_WEHEAR && sendStatus === SEND_STATUS.PENDING)
                                    || (user.data.role === USER_ROLES.WEHEAR_ADMIN && buttonStatus === REPAIRING_STATUS.SEND_TO_WEHEAR && sendStatus === SEND_STATUS.ARRIVED)
                                    || (user.data.role === USER_ROLES.LENSKART_STORE && buttonStatus === REPAIRING_STATUS.SEND_TO_STORE && sendStatus === SEND_STATUS.ARRIVED)
                                    || (user.data.role === USER_ROLES.LENSKART_STORE && buttonStatus === REPAIRING_STATUS.SEND_TO_STORE && sendStatus === SEND_STATUS.PENDING)
                                    // || (buttonStatus === REPAIRING_STATUS.START_REPAIR)
                                    || (buttonStatus === REPAIRING_STATUS.REPAIRING_CONTINUE)
                                    || (buttonStatus === REPAIRING_STATUS.REPAIR_COMPLETED)
                                ) &&
                                    <Button
                                        onClick={onCreateBtnClick}
                                        sx={{
                                            width: "100%",
                                            height: "6vh",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",

                                        }}
                                        variant="contained"
                                    >
                                        {/* <AddIcon /> &nbsp; */}
                                        <Typography variant="h5" sx={{ display: "flex" }}>
                                            {AddButtonName(buttonStatus)}
                                        </Typography>
                                    </Button>
                                }
                            </Box>
                        </FilterTitleBox>
                        <Box
                            mt={4}
                            mb={4}
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexWrap: "wrap",
                                height: "15vh",
                            }}
                        >
                            
                            {countLoading ? (
                                <Box
                                    mt={4}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        flexWrap: "wrap",
                                        height: "15vh",
                                    }}
                                >
                                    {[0, 1, 2, 3].map((row) => (
                                        <Skeleton
                                            key={row}
                                            variant="rectangular"
                                            width={"24%"}
                                            height={120}
                                            sx={{
                                                borderRadius: "8px",
                                                boxShadow:
                                                    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                                            }}
                                        />
                                    ))}
                                </Box>
                            ) : (<>
                            {user.data.role === USER_ROLES.LENSKART_STORE &&
                                <Box mt={4} gap={4} sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: "row", height: "15vh", width: "100%" }}>
                                    <>
                                        <ButtonComponentsUi
                                            count={count[0]?.count ?? 0}
                                            // count={((count[1]?.count ?? 0) + (count[2]?.count ?? 0) + (count[6]?.count ?? 0) + (count[7]?.count ?? 0) ?? 0)}
                                            onSubmit={() => {
                                                setButtonStatus(REPAIRING_STATUS.ALL);

                                            }}

                                            colorType={buttonStatus == REPAIRING_STATUS.ALL}
                                            Title={"All"}
                                        />
                                        <ButtonComponentsUi
                                            count={count[1]?.count ?? 0}
                                            onSubmit={() => setButtonStatus(REPAIRING_STATUS.QUERY_RAISE)}

                                            colorType={buttonStatus == REPAIRING_STATUS.QUERY_RAISE}
                                            Title={"Query Raise"}
                                        />
                                        <ButtonComponentsUi
                                            count={count[4]?.count ?? 0}
                                            onSubmit={() => {
                                                setButtonStatus(REPAIRING_STATUS.SEND_TO_WEHEAR);
                                                setSendStatus(SEND_STATUS.PENDING)
                                            }}

                                            colorType={buttonStatus == REPAIRING_STATUS.SEND_TO_WEHEAR}
                                            Title={"Send To Wehear"}
                                        />
                                        <ButtonComponentsUi
                                            count={count[3]?.count ?? 0}
                                            onSubmit={() => { setButtonStatus(REPAIRING_STATUS.SEND_TO_STORE); setSendStatus(SEND_STATUS.PENDING); }}

                                            colorType={buttonStatus == REPAIRING_STATUS.SEND_TO_STORE}
                                            Title={"Device Recevied"}
                                        />
                                        <ButtonComponentsUi
                                            count={count[2]?.count ?? 0}
                                            onSubmit={() => setButtonStatus(REPAIRING_STATUS.SEND_TO_CUSTOMER)}

                                            colorType={buttonStatus == REPAIRING_STATUS.SEND_TO_CUSTOMER}
                                            Title={"Send To Customer"}
                                        /></> </Box>
                            }

                            {user.data.role === USER_ROLES.WEHEAR_ADMIN &&
                                <Box mt={4} gap={4} sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: "row", height: "15vh", width: "100%" }}>
                                    <>
                                        <ButtonComponentsUi
                                            count={(count[0]?.count ?? 0)}
                                            onSubmit={() => { setButtonStatus(REPAIRING_STATUS.ALL) }}
                                            colorType={buttonStatus == REPAIRING_STATUS.ALL}
                                            Title={"All"}
                                        />
                                        <ButtonComponentsUi
                                            count={count[4]?.count ?? 0}
                                            onSubmit={() => {
                                                setButtonStatus(REPAIRING_STATUS.SEND_TO_WEHEAR);
                                                setSendStatus(SEND_STATUS.PENDING);
                                            }}

                                            colorType={buttonStatus == REPAIRING_STATUS.SEND_TO_WEHEAR}
                                            Title={"Device Recevied"}
                                        />
                                        {/* <ButtonComponentsUi
                                                count={count[1]?.count ?? 0}
                                                onSubmit={() => setButtonStatus(REPAIRING_STATUS.START_REPAIR)}

                                                colorType={buttonStatus == REPAIRING_STATUS.START_REPAIR}
                                                Title={"Start Repair"}
                                            /> */}
                                        <ButtonComponentsUi
                                            count={count[1]?.count ?? 0}
                                            onSubmit={() => setButtonStatus(REPAIRING_STATUS.REPAIRING_CONTINUE)}

                                            colorType={buttonStatus == REPAIRING_STATUS.REPAIRING_CONTINUE}
                                            Title={"Repairing Continue"}
                                        />
                                        <ButtonComponentsUi
                                            count={count[2]?.count ?? 0}
                                            onSubmit={() => setButtonStatus(REPAIRING_STATUS.REPAIR_COMPLETED)}

                                            colorType={buttonStatus == REPAIRING_STATUS.REPAIR_COMPLETED}
                                            Title={"Repair Completed"}
                                        />
                                        <ButtonComponentsUi
                                            count={count[3]?.count ?? 0}
                                            onSubmit={() => {
                                                setButtonStatus(REPAIRING_STATUS.SEND_TO_STORE);
                                                setSendStatus(SEND_STATUS.PENDING);
                                            }}

                                            colorType={buttonStatus == REPAIRING_STATUS.SEND_TO_STORE}
                                            Title={"Send To Store"}
                                        /></>
                                </Box>}

                            </>)
                            }

                        </Box>

                        <Box
                            display="flex"
                            height={"5vh"}
                            alignItems="center"
                            mt={1}
                        >
                            {(buttonStatus == REPAIRING_STATUS.SEND_TO_WEHEAR || buttonStatus == REPAIRING_STATUS.SEND_TO_STORE) && <ButtonGroup sx={{ flexDirection: "row", width: "32vw" }}>
                                <ButtonComponentsUi
                                    // CountButtonGroup={CountButtonGroup}
                                    onSubmit={() => setSendStatus(SEND_STATUS.PENDING)}
                                    ButtonGroup
                                    STATUSWiseData={sendStatus === SEND_STATUS.PENDING}
                                    Title={titleCase(
                                        findObjectKeyByValue(SEND_STATUS.PENDING, SEND_STATUS)
                                    )}
                                />
                                <ButtonComponentsUi
                                    // CountButtonGroup={
                                    //     buttonStatus == REPAIRING_STATUS.SEND_TO_WEHEAR ?
                                    //         (user.data.role === USER_ROLES.WEHEAR_ADMIN ? (count[5]?.count ?? 0) : (count[4]?.count ?? 0))
                                    //         :
                                    //         (user.data.role === USER_ROLES.WEHEAR_ADMIN ? (count[4]?.count ?? 0) : (count[3]?.count ?? 0))
                                    //         -
                                    //         (list?.total ?? 0)
                                    // }
                                    // CountButtonGroup={CountButtonGroup}
                                    count={count[0]?.count ?? 0}
                                    onSubmit={() => setSendStatus(SEND_STATUS.ARRIVED)}
                                    ButtonGroup
                                    STATUSWiseData={sendStatus === SEND_STATUS.ARRIVED}
                                    Title={titleCase(
                                        findObjectKeyByValue(SEND_STATUS.ARRIVED, SEND_STATUS)
                                    )}
                                />
                            </ButtonGroup>}
                            {/* <Box>
                                <PaddingBoxInDesktop
                                    sx={{
                                        display: "flex",
                                        width: isSmallScreen ? "50vw" : "20vw",
                                    }}
                                >
                                    <AsyncSearchBar
                                        fullWidth
                                        title="Search By Name"
                                        size="small"
                                        placeholder={"Search By Name"}
                                        defaultValue={filters?.search}
                                        onChange={(changedVal) =>
                                            setFilters({
                                                ...filters, pageNo: 1,
                                                pageSize: 10, search: changedVal
                                            })
                                        }
                                    />
                                </PaddingBoxInDesktop>
                            </Box> */}
                        </Box>
                    </Box>
{/* 
                    <Box sx={{
                        minHeight: "40vh",
                    }}
                    >
                        <DataTable
                            key={buttonStatus}
                            columns={columns}
                            rows={list?.result ? list?.result : []}
                            count={list?.total ?? 0}
                            filters={filters}
                            setFilters={setFilters}
                            loading={loading}
                        />
                    </Box> */}
                </Paper>
            </Box>
        </>)
}

export default RepairUi