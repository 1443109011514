import { useEffect, useState, useMemo, useRef, memo } from "react";
import { useDispatch } from "react-redux";
import { Box, Chip, IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router";
import moment from "moment";
import SalesListUi from "./SalesListUi";
import { findObjectKeyByValue, OrderColor, titleCase } from "../../utils/main";
import { CUSTOMER_TYPE, ORDER_STATUS } from "../../utils/constants";
import {
  getSalesCountApi,
  getSalesDataApi,
  getSalesValueApi,
  updateSalesStatus,
} from "../../apis/sales.api";
import { callApiAction } from "../../store/actions/commonAction";
import { closeModal, openModal } from "../../store/actions/modalAction";
import { Check } from "@mui/icons-material";
import MessageDilog from "../../components/MessageDilog";

const ActionComponent = ({ callback, params, setParams }) => {
  const dispatch = useDispatch();

  const doneFun = (e) => {
    e.preventDefault();
    dispatch(
      callApiAction(
        async () =>
          await updateSalesStatus({
            id: params._id,
            order_status: ORDER_STATUS.FULFILLED,
          }),
        (response) => {
          setParams({ ...params });
          callback();
          dispatch(closeModal());
        },
        (err) => {}
      )
    );
  };

  const onFullFillFun = () => {
    dispatch(
      openModal(
        <MessageDilog
          onSubmit={doneFun}
          title="Alert!"
          message={`Are you sure to your status is mark as Fullfill ?`}
        />,
        "sm"
      )
    );
  };

  return (
    <Box sx={{ width: "100%", alignItems: "center", display: "flex" }}>
      {params?.sold_details?.order_status == ORDER_STATUS.PENDING && (
        <Tooltip title="Fulfilled">
          <IconButton size="inherit" onClick={onFullFillFun}>
            <Check color="success" fontSize="inherit" />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

const SalesListController = ({ userId, isAccordion = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [countLoading, setCountLoading] = useState(false);
  const [salesValueLoading, setSalesValueLoading] = useState(false);
  const [list, setList] = useState({});
  const [salesValue, setSalesValue] = useState(0);

  const [selectSalesType, setSelectSalesType] = useState(CUSTOMER_TYPE.ALL);
  const [count, setCount] = useState([
    {
      count: 0,
      order_status: ORDER_STATUS.ALL,
      _id: 0,
    },
    {
      count: 0,
      order_status: ORDER_STATUS.FULLFILL,
      _id: 1,
    },
    {
      count: 0,
      order_status: ORDER_STATUS.PENDING,
      _id: 2,
    },
  ]);

  const [filters, setFilters] = useState({
    userId,
    pageNo: 1,
    pageSize: 10,
    searchable: ["first_name", "email", "last_name", "phone"],
    search: "",
    role: "",
    sort: "createdAt",
    sortDirection: -1,
    startDate: "",
    endDate: "",
    deleted: false,
  });
  const fetchcountApi = getSalesCountApi;
  const fetchDataApi = getSalesDataApi;
  const fetchSalesValue = getSalesValueApi;

  const columns = useMemo(() => {
    return [
      {
        id: 1,
        fieldName: "date",
        label: "Date",
        align: "left",
        sort: true,
        renderValue: (params) =>
          moment(params?.sold_details?.date).format("DD/MM/YYYY"),
      },
      {
        id: 2,
        fieldName: "first_name",
        label: "Name",
        align: "left",
        sort: true,
        renderValue: (params) => params?.first_name + " " + params?.last_name,
      },
      {
        id: 3,
        fieldName: "product",
        label: "Product",
        align: "left",
        renderValue: (params) => params?.sold_details?.product?.product_name,
      },
      {
        id: 4,
        fieldName: "amount",
        label: "Amount",
        align: "left",
        renderValue: (params) => params?.sold_details?.amount,
      },
      {
        id: 4,
        fieldName: "status",
        label: "Status",
        align: "left",
        sort: true,
        renderValue: (params) => {
          return (
            <Chip
              size="small"
              key={params?.sold_details?.order_status}
              color={OrderColor(params?.sold_details?.order_status)}
              label={titleCase(
                findObjectKeyByValue(
                  params?.sold_details?.order_status,
                  ORDER_STATUS
                )
              )}
            />
          );
        },
      },
      {
        id: 5,
        fieldName: "",
        label: "Action",
        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            params={params}
            setParams={setParams}
            callback={() => {
              setLoading(true);
              fetchSalesCount();
              fetchSalesList();
            }}
          />
        ),
      },
    ];
  }, [dispatch]);

  const fetchSalesList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await fetchDataApi({ ...filters, sales_type: selectSalesType }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  const fetchSalesValueFun = () => {
    setSalesValueLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchSalesValue({ ...filters }),
        (response) => {
          setSalesValue(response?.length > 0 ? response[0].totalAmount : 0);
          setSalesValueLoading(false);
        },
        (err) => {
          setSalesValueLoading(false);
        }
      )
    );
  };

  const fetchSalesCount = () => {
    setCountLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchcountApi({ ...filters }),
        (response) => {
          setCount(response);
          setCountLoading(false);
        },
        (err) => {
          setCountLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchSalesValueFun();
    fetchSalesList();
  }, [filters, selectSalesType]);

  useEffect(() => {
    fetchSalesCount();
  }, [filters.startDate, filters.endDate]);

  return (
    <SalesListUi
      salesValue={salesValue}
      columns={columns}
      list={list}
      filters={filters}
      setFilters={setFilters}
      count={count}
      selectSalesType={selectSalesType}
      setSelectSalesType={setSelectSalesType}
      salesValueLoading={salesValueLoading}
      countLoading={countLoading}
      loading={loading}
    />
  );
};
export default memo(SalesListController);
