import axios from "axios";
import endpoints from "./endpoints";
import { getHeaders } from "../utils/main";



export const getTrialsCountApi = async (params) => {
    const callResponse = await axios({
        url: endpoints.trialsCountApi,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then((response) => response.data)
        .catch((err) => err.response.data);

    return callResponse;
};

export const getTrialsDataApi = async (params) => {
    const callResponse = await axios({
        url: endpoints.trialsDataApi,
        method: "get",
        headers: getHeaders(),
        params,
    })
        .then((response) => response.data)
        .catch((err) => err.response.data);

    return callResponse;
};

