import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { openModal } from "../../store/actions/modalAction";
import { fetchDemoUnitsApi } from "../../apis/inventory.api";
import DemoUnitListUi from "./DemoUnitListUi";
import ChannelSaleCreateController from "../StockProduct/ChannelSaleCreateController";

const DemoUnitListController = ({ title, id }) => {
  const dispatch = useDispatch();
  const fetchApi = fetchDemoUnitsApi;
  const { user } = useSelector((state) => state)
  console.log("object user", user);
  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    searchable: ["name", "address"],
    search: "",
    role: "",
    sort: "",
    sortDirection: -1,
    deleted: null,
  });
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  console.log("object iddddddd", id);

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ user_Id: id || user.data._id }),
        (response) => {
          setList(response);
          console.log("object list", response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };
  console.log("object iddddddd", id);

  useEffect(() => {
    fetchList();
  }, []);

  const onChannelSaleClick = () => {
    dispatch(openModal(<ChannelSaleCreateController />, "md", false));
  };

  return (
    <>
      <DemoUnitListUi
        title={title}
        list={list}
        onChannelSaleClick={onChannelSaleClick}
        id={id}
        loading={loading}
      />
    </>
  );
};

export default DemoUnitListController;
