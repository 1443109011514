import React, { memo, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../store/actions/modalAction';
import { REPAIRING_STATUS } from '../../utils/constants';
import { Box, IconButton } from '@mui/material';
import RepairUi from './RepairUi';
import moment from 'moment';
import CustomerInformation from './CustomerInformation';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';


const ActionComponent = memo(({ params, setParams }) => {
    const dispatch = useDispatch();

    const onView = () => {
        dispatch(openModal(<CustomerInformation params={params} />, "lg", true, "CustomerInformation"))
    }
    return (
        <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>

            <IconButton size="inherit" onClick={onView}>
                <RemoveRedEyeIcon color="info" fontSize="inherit" />
            </IconButton>

        </Box>
    );
});

const RepairController = () => {
    const { Query, user } = useSelector((state) => state);
    const [buttonStatus, setButtonStatus] = useState(REPAIRING_STATUS.ALL);
    const [countLoading, setCountLoading] = useState(false);
    const dispatch = useDispatch();
    const [selectedButtonId, setSelectedButtonId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [sendStatus, setSendStatus] = useState();
    const columns = useMemo(
        () => [
            {
                id: 6,
                fieldName: "createdAt",
                label: "Created Date",
                align: "left",
                sort: true,
                renderValue: (params, setParams) => moment(params?.createdAt).format("DD/MM/YYYY")
            },
            {
                id: 2,
                fieldName: "customer_name",
                label: "Name",
                align: "left",
                sort: true,
            },
            {
                id: 3,
                fieldName: "customer_phone",
                label: "Phone",
                align: "left",
                sort: true,
            },
            {
                id: 4,
                fieldName: "customer_email",
                label: "Email",
                align: "left",
                sort: true,
            },
            {
                id: 1,
                fieldName: "box_id",
                label: "Box Id",
                align: "left",
                sort: true,

            },

            {
                id: 7,
                fieldName: "warrenty_date",
                label: "Warrenty Date",
                align: "left",
                sort: false,
                renderValue: (params, setParams) => moment(params?.warrenty_date).format("DD/MM/YYYY")
            },

            {
                id: 8,
                fieldName: "",
                label: "Action",
                align: "right",
                renderValue: (params, setParams) => (
                    <ActionComponent params={params} setParams={setParams} />
                ),
            },
        ],
        [dispatch]
    );


    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: "",
        searchable: ["customer_name"],
        sort: "createdAt",
        sortDirection: -1,
        startDate: moment().startOf("day").valueOf(),
        endDate: moment().endOf("day").valueOf(),
    });

    const getQueryList = () => {

    };

    useEffect(() => {
        getQueryList();
    }, [filters, buttonStatus, sendStatus]);


    const [count, setCount] = useState([]);
    const fetchCount = () => {
        setCountLoading(true);
        dispatch(

        );
    };

    useEffect(() => {
        fetchCount();
    }, [filters]);




    return (
        <RepairUi
            filters={filters}
            setFilters={setFilters}
            loading={Query.Query_loading}
            list={Query.Query_data}
            columns={columns}
            countloading={countLoading}
            count={count}
            setButtonStatus={setButtonStatus}
            buttonStatus={buttonStatus}
            selectedButtonId={selectedButtonId}
            setSelectedButtonId={setSelectedButtonId}
            setSendStatus={setSendStatus}
            sendStatus={sendStatus}
        />)
}

export default RepairController