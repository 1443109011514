import { Box, Button, ButtonGroup, Typography } from "@mui/material"
import { useState } from "react";
import { useSelector } from "react-redux";
import { USER_ROLES } from "../../utils/constants";
import ScpOrFranchiseList from "../../components/ScpOrFranchiseList";
import TrialsListController from "./TrialsListController";

const TrialsMain = () => {
    const [step, setStep] = useState(0);
    const { user } = useSelector((state) => state);
    return (
        <Box>
            {user.data.role != USER_ROLES.FRANCHISE_PARTNER && (
                <Box mb={2}>
                    <ButtonGroup
                        sx={{ color: "#101536" }}
                        fullWidth
                        size="large"
                        aria-label="Large button group"
                    >
                        {(user.data.role == USER_ROLES.WEHEAR_ADMIN ||
                            user.data.role == USER_ROLES.SALES_CHANNEL_PARTNER) && (
                                <Button
                                    sx={step !== 0 && { backgroundColor: "white" }}
                                    variant={step == 0 ? "contained" : "outlined"}
                                    onClick={() => setStep(0)}
                                    fullWidth
                                >
                                    ALL
                                </Button>
                            )}
                        {user.data.role == USER_ROLES.WEHEAR_ADMIN && (
                            <Button
                                sx={step !== 1 && { backgroundColor: "white" }}
                                variant={step == 1 ? "contained" : "outlined"}
                                onClick={() => setStep(1)}
                                fullWidth
                            >
                                SCP
                            </Button>
                        )}
                        {(user.data.role == USER_ROLES.WEHEAR_ADMIN ||
                            user.data.role == USER_ROLES.SALES_CHANNEL_PARTNER) && (
                                <Button
                                    sx={step !== 2 && { backgroundColor: "white" }}
                                    variant={step == 2 ? "contained" : "outlined"}
                                    onClick={() => setStep(2)}
                                    fullWidth
                                >
                                    Franchise
                                </Button>
                            )}
                    </ButtonGroup>
                </Box>
            )}
            {step == 0 && <TrialsListController />}
            {step == 1 && <ScpOrFranchiseList Component={(props) => (<TrialsListController {...props} />)} step={step} />}
            {step == 2 && <ScpOrFranchiseList Component={(props) => (<TrialsListController {...props} />)} step={step} />}
        </Box>
    );
}

export default TrialsMain;