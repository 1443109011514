import React, { useState } from 'react'
import { Box, Button, ButtonGroup } from "@mui/material";
import CustomizedAccordions from "../../components/AccordionComponent";
import ScpOrFranchiseList from "../../components/ScpOrFranchiseList";
import { USER_ROLES } from "../../utils/constants";
import { useSelector } from "react-redux";
import DemoUnitListController from './DemoUnitListController';
import Franchise from './Franchise';
import FranchiseController from './FranchiseController';

const DemoList = () => {
  const { user } = useSelector((state) => state);
  const [step, setStep] = useState(((user.data.role == USER_ROLES.FRANCHISE_PARTNER) || (user.data.role == USER_ROLES.FRANCHISE_STAFF)) ? 2 : 1);
    return (
      <Box>
        {user.data.role != USER_ROLES.FRANCHISE_PARTNER && (
          <Box mb={2}>
            <ButtonGroup
              sx={{ color: "#101536" }}
              fullWidth
              size="large"
              aria-label="Large button group"
            >
              {/* {(user.data.role == USER_ROLES.WEHEAR_ADMIN ||
                user.data.role == USER_ROLES.SALES_CHANNEL_PARTNER) && (
                <Button
                  sx={step !== 0 && { backgroundColor: "white" }}
                  variant={step == 0 ? "contained" : "outlined"}
                  onClick={() => setStep(0)}
                  fullWidth
                >
                  ALL
                </Button>
              )} */}
              {(user.data.role == USER_ROLES.WEHEAR_ADMIN ||
                user.data.role == USER_ROLES.SALES_CHANNEL_PARTNER) && (
                <Button
                  sx={step !== 1 && { backgroundColor: "white" }}
                  variant={step == 1 ? "contained" : "outlined"}
                  onClick={() => setStep(1)}
                  fullWidth
                >
                  {user.data.role == USER_ROLES.SALES_CHANNEL_PARTNER ? "My Demo Device" : "SCP"}
                </Button>
              )}
              {(user.data.role == USER_ROLES.WEHEAR_ADMIN ||
                user.data.role == USER_ROLES.SALES_CHANNEL_PARTNER) && (
                <Button
                  sx={step !== 2 && { backgroundColor: "white" }}
                  variant={step == 2 ? "contained" : "outlined"}
                  onClick={() => setStep(2)}
                  fullWidth
                >
                  Franchise
                </Button>
              )}
            </ButtonGroup>
          </Box>
        )}
        {/* {step == 0 && <DemoUnitListController />} */}
        {step == 1 && (((user.data.role == USER_ROLES.SALES_CHANNEL_PARTNER) || (user.data.role == USER_ROLES.SALES_STAFF)) ? (<DemoUnitListController step={step} />) : (<ScpOrFranchiseList Component={DemoUnitListController} step={step} />) )}
        {step == 2 && (((user.data.role == USER_ROLES.FRANCHISE_PARTNER) || (user.data.role == USER_ROLES.FRANCHISE_STAFF)) ? (<FranchiseController step={step}/>) : (<ScpOrFranchiseList Component={FranchiseController } step={step} />))}
      </Box>
    );
}

export default DemoList