import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { FilterTitleBox } from "../../components/layouts/OneViewBox";
import { findObjectKeyByValue, titleCase } from "../../utils/main";
import {
  BUTTON_STATUS_COLOR_TYPE,
  BUTTON_TYPE,
  STATUS,
  STATUS_COLOR_TYPE,
} from "../../utils/constants";
import { formatNumberCustomPattern } from "../../utils/helper";
import { current } from "@reduxjs/toolkit";

const ButtonComponentsUi = ({
  count,
  onSubmit,
  Title,
  ButtonGroup,
  colorType,
  STATUSWiseData,
  CountButtonGroup
}) => {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("lg"))

  return (
    <>
      {!ButtonGroup ? (
        // <Card
        //   variant="outlined"
        //   onClick={onSubmit}
        //   sx={{
        //     backgroundColor: STATUS_COLOR_TYPE(colorType),
        //     width: "18vw",
        //     borderRadius: "8px",
        //     border: colorType ? "3px solid #1B4381" : "1px solid #CCCCCC",
        //     cursor: "pointer",
        //   }}
        // >
        //   <Box mt={3}>
        //     <Box
        //       sx={{
        //         display: "flex",
        //         justifyContent: "center",
        //         alignItems: "center",
        //       }}
        //     >
        //       <Typography
        //         variant={isSmallScreen ? "body2" : "h5"}
        //         sx={{
        //           fontWeight: "bold",
        //           color: colorType ? "white" : "#1B4381",
        //         }}
        //       >
        //         {Title}
        //       </Typography>
        //     </Box>
        //     <Box
        //       sx={{
        //         display: "flex",
        //         alignItems: "center",
        //         justifyContent: "space-between",
        //         borderRadius: "4px",
        //         marginTop: 2,
        //       }}
        //     >
        //       <Box sx={{ textAlign: "center", flex: 1 }}>
        //         <Typography
        //           variant={isSmallScreen ? "h2" : "h1"}
        //           sx={{
        //             fontWeight: "medium",
        //             color: colorType ? "white" : "#1B4381",
        //           }}
        //         >
        //           {formatNumberCustomPattern(count)}
        //         </Typography>
        //       </Box>
        //     </Box>
        //   </Box>
        // </Card>

        <Card
          variant="outlined"
          onClick={onSubmit}
          sx={{
            backgroundColor: STATUS_COLOR_TYPE(colorType),
            width: "18vw",
            borderRadius: "8px",
            border: colorType ? "3px solid #1B4381" : "1px solid rgb(129, 166, 222)",
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px",
              flexDirection:"column"
              // flexDirection: { sm: "row", xs: "column" }
            }}
          >
            <Typography
              variant={isSmallScreen ? "body2" : "h4"}
              sx={{
                fontWeight: "bold",
                color: colorType ? "white" : "#1B4381",
                textTransform: "uppercase",
              }}
            >
              {Title}
            </Typography>

            <Typography
              variant={isSmallScreen ? "h4" : "h1"}
              sx={{
                fontWeight: "bold",
                color: colorType ? "white" : "#1B4381",
              }}
            >
              {formatNumberCustomPattern(count)}
            </Typography>
          </Box>
        </Card>

      ) : (
        <Button
          onClick={onSubmit}
          variant={BUTTON_STATUS_COLOR_TYPE(STATUSWiseData)}
          sx={{
            height: "5vh",
            width: "100%",
            borderRadius: "8px",
            backgroundColor: STATUS_COLOR_TYPE(STATUSWiseData),
            border: STATUSWiseData ? "2px solid #1B4381" : "1px solid #1B4381",
            padding: "5px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              fontSize: "20px",
              color: STATUSWiseData ? "#fff" : "text.primary",
            }}
          >
            {Title}  &nbsp; {CountButtonGroup &&
              <>
                ( &nbsp;{formatNumberCustomPattern(CountButtonGroup)}&nbsp; )
              </>
            }
          </Typography>

        </Button>
      )}
    </>
  );
};

export default ButtonComponentsUi;
