import {
  Box,
  Paper,
  Skeleton,
  styled,
  Typography,
  useMediaQuery,
  ButtonGroup,
  Grid2,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxDesktop";
import TimeRangeSelector from "../../components/layouts/common/TimeRangeSelector";
import ButtonComponentsUi from "../../components/button/ButtonComponentsUi";
import { INVENTORY_TYPE, USER_ROLES } from "../../utils/constants";
import { findObjectKeyByValue, titleCase } from "../../utils/helper";
import AsyncSearchBar from "../../components/inputs/AsyncSearchBar";
import DataTable from "../../components/tables/DataTable";
import ChannelSaleCreateController from "../StockProduct/ChannelSaleCreateController";
import { openModal } from "../../store/actions/modalAction";
import ReturnModel from "./ReturnModel";

const FilterTitleBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  alignItems: "center",
  padding: "8px",
  marginTop: "12px",
}));
const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const ReturnDataListUi = ({
  setButtonStatus,
  selectedButtonId,
  setSelectedButtonId,
  buttonStatus,
  columns,
  list,
  filters,
  setFilters,
  itemType,
  loading,
  count,
  countLoading,
  current,
  setCurrent,
  callback,
}) => {
  const user = useSelector((state) => state.user);
  const { settings } = useSelector((state) => state);
  const dispatch = useDispatch();

  const onChannel = (arrived) => {
    dispatch(
      openModal(
        <ReturnModel callback={callback} arrived={arrived} current={current} />,
        "md",
        false
      )
    );
  };

  

  return (
    <Box sx={{ scrollbarWidth: "0px" }}>
      <Paper sx={{ padding: 4, width: "100%" }}>
        <Box>
          <Typography variant="h3">Return</Typography>
        </Box>

        {/* <FilterTitleBox>
          {user.data.role == USER_ROLES.LENSKART_STORE && (
            <Box width={"48%"}>
              <ButtonGroup sx={{ flexDirection: "row", width: "100%" }}>
                <ButtonComponentsUi
                  STATUSWiseData={current}
                  onSubmit={() => {
                    setCurrent(true);
                  }}
                  ButtonGroup
                  Title={"Returned Stock"}
                />
                <ButtonComponentsUi
                  onSubmit={() => {
                    setCurrent(false);
                  }}
                  STATUSWiseData={!current}
                  ButtonGroup
                  Title={"Pending to wehear"}
                />
              </ButtonGroup>
            </Box>
          )}
          {user.data.role == USER_ROLES.WEHEAR_ADMIN && (
            <Box width={"48%"}>
              <ButtonGroup sx={{ flexDirection: "row", width: "100%" }}>
                <ButtonComponentsUi
                  STATUSWiseData={current}
                  onSubmit={() => {
                    setCurrent(true);
                  }}
                  ButtonGroup
                  Title={"Arrived"}
                />
                <ButtonComponentsUi
                  onSubmit={() => {
                    setCurrent(false);
                  }}
                  STATUSWiseData={!current}
                  ButtonGroup
                  Title={"Pending"}
                />
              </ButtonGroup>
            </Box>
          )}
          <Box>
            <PaddingBoxInDesktop
              sx={{
                display: "flex",
                flexDirection: "flex-end",
              }}
            >
              {user.data.role == USER_ROLES.LENSKART_STORE && (
                <Button
                  onClick={() => {
                    onChannel(false);
                  }}
                  variant="contained"
                >
                  <Typography>Return to wehear</Typography>
                </Button>
              )}
              {user.data.role == USER_ROLES.WEHEAR_ADMIN && !current && (
                <Button
                  onClick={() => {
                    onChannel(true);
                  }}
                  variant="contained"
                >
                  <Typography>Arrived</Typography>
                </Button>
              )}
            </PaddingBoxInDesktop>
          </Box>
        </FilterTitleBox> */}

        {countLoading ? (
          <Box
            mt={4}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              height: "20vh",
            }}
          >
            {[0, 1, 2, 3].map((row) => (
              <Skeleton
                key={row}
                variant="rectangular"
                width={"24%"}
                height={120}
                sx={{
                  borderRadius: "8px",
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                }}
              />
            ))}
          </Box>
        ) : (
          <Grid2
            container
            mt={4}
            p={2}
            size={12}
            justifyContent={"space-between"}
          >
            {" "}
            {count?.map((listData) => (
              <>
                <Grid2
                  display={"flex"}
                  mb={4}
                  width={"18vw"}
                  flexDirection={"row"}
                  justifyContent={"flex-start"}
                  flexWrap={"wrap"}
                  size={listData.length / 3}
                >
                  <ButtonComponentsUi
                    count={listData?.count ?? 0}
                    onSubmit={() => {
                      setSelectedButtonId(listData._id);
                    }}
                    colorType={selectedButtonId === listData._id}
                    Title={listData?.product_name}
                  />
                </Grid2>
              </>
            ))}
          </Grid2>
        )}
        <Box p={2} sx={{ minHeight: "40vh" }}>
          <DataTable
            columns={columns}
            rows={list?.result ? list.result : []}
            count={list?.result?.length ?? 0}
            filters={filters}
            setFilters={setFilters}
            loading={loading}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default ReturnDataListUi;
