import { Box, Button, ButtonGroup, Paper, Typography } from '@mui/material'
import React, { useState } from 'react'
import CustomisedDayPicker from '../../components/layouts/common/CustomisedDayPicker'
import DataTable from '../../components/tables/DataTable'
import { useSelector } from 'react-redux'
import { USER_ROLES } from '../../utils/constants'
import AsyncSearchBar from '../../components/inputs/AsyncSearchBar'
import PaddingBoxInDesktop from '../../components/layouts/PaddingBoxDesktop'

const IncentiveListUi = ({ date, setDate, setStep, step, columns, list, filters, setFilters, loading }) => {
    const { user } = useSelector(state => state)
    return (
        <Box>
            <Paper sx={{ width: "100%", padding: 6 }}>
                <Box mb={4} display="flex" flexDirection="column" width="100%">
                    <ButtonGroup
                        sx={{
                            width: "100%",
                            flexWrap: "wrap",
                            color: "#101536"
                        }}
                    >
                        {((user.data.role === USER_ROLES.WEHEAR_ADMIN) || (user.data.role === USER_ROLES.SALES_CHANNEL_PARTNER)) &&
                            <Button sx={{ flex: 1, minWidth: "100px" }}
                                variant={step == 2 ? "contained" : "outlined"}
                                onClick={() => setStep(2)}
                            >
                                <Typography variant="body1" color={step === 2 && "white"}>SCP</Typography>
                            </Button>}
                        {((user.data.role === USER_ROLES.WEHEAR_ADMIN) || (user.data.role === USER_ROLES.FRANCHISE_PARTNER) || (user.data.role === USER_ROLES.SALES_CHANNEL_PARTNER)) &&
                            <Button sx={{ flex: 1, minWidth: "100px" }}
                                onClick={() => setStep(3)}
                                variant={step == 3 ? "contained" : "outlined"}
                            >
                                <Typography variant="body1" color={step === 3 && "white"}>FP</Typography>
                            </Button>}
                        {((user.data.role === USER_ROLES.WEHEAR_ADMIN) || (user.data.role === USER_ROLES.SALES_CHANNEL_PARTNER) || (user.data.role === USER_ROLES.SALES_STAFF) || (user.data.role === USER_ROLES.FRANCHISE_PARTNER)) &&
                            <Button sx={{ flex: 1, minWidth: "100px" }}
                                onClick={() => setStep(5)}
                                variant={step == 5 ? "contained" : "outlined"}
                            >
                                <Typography variant="body1" color={step === 5 && "white"}>Sales Staff</Typography>
                            </Button>}
                    </ButtonGroup>
                </Box>
                <Box
                    mt={2}
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <CustomisedDayPicker date={date} setDate={setDate} />
                </Box>
                <Box sx={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
                    <PaddingBoxInDesktop p={2} sx={{ display: "flex", width: "20vw" }}>
                        <AsyncSearchBar
                            fullWidth
                            title="Search Name | Email"
                            size="small"
                            placeholder={"Search Name | Email"}
                            defaultValue={filters?.search}
                            onChange={(changedVal) => {
                                setFilters({
                                    ...filters,
                                    pageNo: 1,
                                    pageSize: 10,
                                    search: changedVal,
                                });
                            }}
                        />
                    </PaddingBoxInDesktop>
                </Box>
                <Box
                    mt={3}
                    p={1}
                // sx={{
                //     height: "40vh",
                // }}
                >
                    <DataTable columns={columns} rows={list?.result?.length > 0 ? list?.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                </Box>
            </Paper>
        </Box>)
}

export default IncentiveListUi