import { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "../../store/actions/modalAction";
import { callApiAction } from "../../store/actions/commonAction";
import { Delete, Edit } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import MessageDilog from "../../components/texts/MessageDilog";
import {
  DEVICE_STATUS,
  SNACK_BAR_VARIETNS,
  USER_ROLES,
} from "../../utils/constants";

import { deleteProductApi, fetchProductApi } from "../../apis/product.api";

import { callSnackBar } from "../../store/actions/snackbarAction";
import StockOfProductListUi from "./StockOfProductListUI";
import ImportCsvCreateController from "./ImportCsvCreateController";
import { fetchProductStockApi } from "../../apis/productStock.api";
import ChannelSaleCreateController from "./ChannelSaleCreateController";
import { fetchProductAction } from "../../store/actions/setting.Action";
import { fetchProductCount } from "../../apis/inventory.api";

const ActionComponent = memo(({ params, setParams, deleteApi }) => {
  const dispatch = useDispatch();
  const modalkey = "productupdate";
  const [loading, setLoading] = useState(false);
  const { settings } = useSelector((state) => state);
  const user = useSelector((state) => state.user);

  const onEdit = () => {
    dispatch(openModal(<ImportCsvCreateController />, "sm", false, modalkey));
  };

  const deleteFun = async (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await deleteApi({ id: params._id }),
        (response) => {
          setParams({});
          setLoading(false);
          dispatch(
            callSnackBar(
              params.product_name + " Deleted Successfully",
              SNACK_BAR_VARIETNS.suceess
            )
          );
          dispatch(closeModal("productidd"));
          // const updateData = settings.rawMaterial_data.filters(item => item._id != params._id)
          // dispatch(deleteProductAction(updateData, settings.rawMaterial_filters))
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  const onDelete = () => {
    dispatch(
      openModal(
        <MessageDilog
          onSubmit={deleteFun}
          title="Alert!"
          modalId="productidd"
          message={`Are you sure to delete "${params.product_name || params.title
            }" ?`}
        />,
        "sm",
        false,
        "productidd"
      )
    );
  };

  return (
    <Box sx={{ width: "100%", alignItems: "flex-start", display: "flex" }}>
      {user.data.role === USER_ROLES.WEHEAR_ADMIN ? (
        <IconButton size="inherit" onClick={onEdit}>
          <Edit color="info" fontSize="inherit" />
        </IconButton>
      ) : (
        ""
      )}
      {user.data.role === USER_ROLES.WEHEAR_ADMIN ? (
        <IconButton disabled={loading} size="inherit" onClick={onDelete}>
          <Delete color="error" fontSize="inherit" />
        </IconButton>
      ) : (
        ""
      )}
    </Box>
  );
});

const StockOfProductListController = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { settings, user } = useSelector((state) => state);
  const title = "Inventory";
  const fetchApi = fetchProductApi;
  const deleteApi = deleteProductApi;
  const [selectedButtonId, setSelectedButtonId] = useState(null);
  const [count, setCount] = useState([]);
  const fetchCountApi = fetchProductCount;
  const [loadingCount, setLoadingCount] = useState(true);

  const columns = useMemo(
    () => [
      {
        id: 1,
        fieldName: "product_id",
        label: "Product",
        align: "left",

        renderValue: (params) => params?.product_id?.product_name,
      },
      {
        id: 2,
        fieldName: "box_id",
        label: "Box ID",
        align: "left",
        sort: true,
      },
      {
        id: 3,
        fieldName: "product_color",
        label: "Color",
        align: "left",
        renderValue: (params) => params?.product_id?.product_color?.name,
      },
      {
        id: 4,
        fieldName: "product_type",
        label: "Type",
        align: "left",
        renderValue: (params) => params?.product_id?.product_type?.name,
      },
    ],
    [selectedButtonId, dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["box_id"],
    sort: "createdAt",
    sortDirection: -1,
    // category:0
  });

  const [productList, setProductList] = useState([]);

  const [list, setList] = useState({});

  const fetchProductList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await fetchProductStockApi({
            ...filters,
            product_id: selectedButtonId,
            device_status:
              user.data.role == USER_ROLES.WEHEAR_ADMIN
                ? DEVICE_STATUS.AVAILABLE
                : DEVICE_STATUS.IN_STORE,
          }),
        (response) => {
          setProductList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    if (selectedButtonId) {
      fetchProductList();
    }
  }, [selectedButtonId, filters]);

  useEffect(() => {
    dispatch(fetchProductAction());
  }, []);

  const onExportClick = () => {
    dispatch(
      openModal(
        <ImportCsvCreateController product_id={selectedButtonId} />,
        "md",
        false
      )
    );
  };

  const onChannelSaleClick = () => {
    dispatch(openModal(<ChannelSaleCreateController
      callBack={() => {
        fetchCount();
      }} />, "md", false));
  };

  // useEffect(() => {
  //   if (count?.length > 0) {
  //     setSelectedButtonId(count[0]._id);
  //   }
  // }, [count]);

  const fetchCount = () => {
    setLoadingCount(true);
    dispatch(
      callApiAction(
        async () =>
          await fetchCountApi({
            device_status:
              user.data.role == USER_ROLES.WEHEAR_ADMIN
                ? DEVICE_STATUS.AVAILABLE
                : DEVICE_STATUS.IN_STORE,
          }),
        (response) => {
          setCount(response);
          setLoadingCount(false);
        },
        (err) => {
          setLoadingCount(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchCount();
  }, []);
  useEffect(() => {
    if (count.length > 0) {
      if (!selectedButtonId) {
        setSelectedButtonId(count[0]._id);
      } else {
        fetchProductList();
      }
    }
  }, [count]);

  return (
    <>
      <StockOfProductListUi
        title={title}
        filters={filters}
        onExportClick={onExportClick}
        onChannelSaleClick={onChannelSaleClick}
        setFilters={setFilters}
        loading={loading}
        list={productList}
        columns={columns}
        setSelectedButtonId={setSelectedButtonId}
        selectedButtonId={selectedButtonId}
        count={count}
        loadingCount={loadingCount}
      />
    </>
  );
};
export default StockOfProductListController;
