import { useEffect, useState, useMemo, useRef, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../../store/actions/commonAction";
import { closeModal, openModal } from "../../../store/actions/modalAction";
import { Box, IconButton, Tooltip } from "@mui/material";
import MessageDilog from "../../../components/MessageDilog";
import {
  CUSTOMER_TYPE,
  GENDER,
  SNACK_BAR_VARIETNS,
} from "../../../utils/constants";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import CustomerListUi from "./CustomerListUi";
import {
  deleteCustomerApi,
  getCustomerCountApi,
  getCustomerListApi,
} from "../../../apis/customer.api";
import { findObjectKeyByValue } from "../../../utils/helper";
import { useNavigate } from "react-router";
import VisibilityIcon from "@mui/icons-material/Visibility";
import StepperController from "../CustomerCreate/CustomerCreateController";
import CustomerInformationController from "./CustomerInformationController";
import moment from "moment";

const ActionComponent = ({ params, setParams, deleteApi, callBack }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state);

  const onEdit = () => {
    dispatch(
      openModal(
        <StepperController id={params._id} />,
        "md",
        false,
        "CustomerInformation"
      )
    );
  };

  const onVeiw = (id) => {
    dispatch(
      openModal(
        <CustomerInformationController id={params._id} callBack={callBack} />,
        "xl",
        false,
        "CustomerInformation"
      )
    );
  };

  const deleteFun = async (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await deleteApi({ id: params._id }),
        (response) => {
          setParams({});
          setLoading(false);
          dispatch(
            callSnackBar(
              params.first_name + "Deleted Successfully",
              SNACK_BAR_VARIETNS.suceess
            )
          );
        },
        (err) => {
          setLoading(false);
        }
      )
    );
    dispatch(closeModal());
  };

  const onDelete = (e) => {
    dispatch(
      openModal(
        <MessageDilog
          onSubmit={deleteFun}
          title="Alert!"
          message={`Are you sure you want to delete customer "${
            params.first_name || params.title
          }" ?`}
        />,
        "sm"
      )
    );
  };
  return (
    <Box sx={{ width: "100%", alignItems: "center", display: "flex" }}>
      <Tooltip title="View">
        <IconButton size="inherit" onClick={onVeiw}>
          <VisibilityIcon color="info" fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

const CustomerListController = ({ userId, isAccordion = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});
  const [selectCustomerType, setSelectCustomerType] = useState(
    CUSTOMER_TYPE.ALL
  );
  const [count, setCount] = useState([
    {
      count: 0,
      customer_type: CUSTOMER_TYPE.ALL,
      _id: 0,
    },
    {
      count: 0,
      customer_type: CUSTOMER_TYPE.TRIALS,
      _id: 1,
    },
    {
      count: 0,
      customer_type: CUSTOMER_TYPE.PURCHASED,
      _id: 2,
    },
    {
      count: 0,
      customer_type: CUSTOMER_TYPE.RETURN,
      _id: 3,
    },
  ]);
  const [countLoading, setCountLoading] = useState(false);
  const [filters, setFilters] = useState({
    userId,
    pageNo: 1,
    pageSize: 10,
    searchable: ["first_name", "email", "last_name", "phone"],
    search: "",
    role: "",
    sort: "createdAt",
    sortDirection: -1,
    startDate: "",
    endDate: "",
    deleted: false,
  });
  const fetchcountApi = getCustomerCountApi;
  const fetchApi = getCustomerListApi;
  const deleteApi = deleteCustomerApi;
  const title = "Customers";

  const columns = useMemo(() => {
    return [
      {
        id: 1,
        minWidth: "50px",
        fieldName: "first_name",
        label: "Name",
        align: "left",
        sort: true,
        renderValue: (params) => params?.first_name + " " + params?.last_name,
      },
      {
        id: 2,
        fieldName: "email",
        label: "Email",
        align: "left",
        sort: true,
      },
      {
        id: 3,
        fieldName: "phone",
        label: "Phone",
        align: "left",
        sort: true,
      },
      {
        id: 4,
        minWidth: "50px",
        fieldName: "gender",
        label: "Gender",
        align: "left",
        renderValue: (params) =>
          findObjectKeyByValue(parseInt(params.gender), GENDER),
      },
      {
        id: 5,
        fieldName: "",
        label: "Action",
        align: "right",
        renderValue: (params, setParams) => (
          <ActionComponent
            deleteApi={deleteApi}
            params={params}
            setParams={setParams}
            callBack={() => {
              fetchCustomerCount();
              fetchCustomerList();
            }}
          />
        ),
      },
    ];
  }, [dispatch]);

  const createCustomerFun = () => {
    navigate("/customer/create");
  };

  const fetchCustomerList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await fetchApi({ ...filters, customer_type: selectCustomerType }),
        (response) => {
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  const fetchCustomerCount = () => {
    setCountLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchcountApi({ ...filters }),
        (response) => {
          setCount(response);
          setCountLoading(false);
        },
        (err) => {
          setCountLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchCustomerList();
  }, [filters, selectCustomerType]);

  useEffect(() => {
    fetchCustomerCount();
  }, [filters.startDate, filters.endDate]);

  return (
    <CustomerListUi
      title={title}
      createCustomerFun={createCustomerFun}
      filters={filters}
      setFilters={setFilters}
      loading={loading}
      list={list}
      columns={columns}
      isAccordion={isAccordion}
      selectCustomerType={selectCustomerType}
      setSelectCustomerType={setSelectCustomerType}
      count={count}
      countLoading={countLoading}
    />
  );
};
export default memo(CustomerListController);
